<template>
    <div class="head">
        <div class="head_background_color">
            <router-link to="/">
                <div class="logo_background_img"></div>
                <div class="shugang"></div>
                <div class="tongxingzheng_zhuce">个人中心</div>
            </router-link>
        </div>

        <!-- 登录之后的 -->
        <div class="head_sculpture_position_img" v-if="null != session_id">
            <div class="head_sculpture"></div>
        </div>
        <div class="userName_position" v-if="null != session_id">
            <el-popover
                placement="top-start"
                trigger="hover"
                :width="141">
                <router-link to="/personalCenterView">
                    <div class="returnStyle" id="perFaId" @mouseenter="getFocus(1)" @mouseleave="lostFocus(1)">
                        <div class="returnClassType" style="left:45px;" id="perId">个人中心</div>
                    </div>
                </router-link>
                <a href="javaScript:void(0);" @click="confirmEvent()" @mouseenter="getFocus(2)" @mouseleave="lostFocus(2)" id="retFaId" class="returnStyle" style="top: 46px;">
                    <div class="returnClassType" id="retId">退出</div>
                </a> 
                <template #reference>
                    <a class="adminExit" href="javaScript:void(0);">{{ user_name_admin }}</a>
                </template>
            </el-popover>
        </div>

        <!-- 登录前的 -->
        <div class="login" v-if="null == session_id">
            <el-button class="loginButtonStyle" type="primary" plain @click="toLogin()">登录/注册</el-button>
        </div>
    </div>
    <div class="per_body_all">
        <div class="per_body_all_side">
            <div class="is_side">
                <div class="per_center_characters">个人中心</div>
            </div>
            <div class="is_body_bg">
                <div class="account_information_pos">账号信息：{{ accountInformation }}</div>
                <div class="pre_henggang"></div>
                <div class="pre_shugang"></div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "@/utils/request";

export default {
    data() {
        return {
            loginStatus: '0',
            user_name_admin: '',
            session_id: '',
            accountInformation: sessionStorage.getItem('USER_NAME')
        }
    },
    created() {
        var that = this;
        that.user_name_admin = sessionStorage.getItem('USER_NAME')
        that.session_id = sessionStorage.getItem('SESSION_ID')
    },
    methods: {
        getFocus(e){
            if(e == 1){
                document.getElementById('perFaId').style.backgroundColor = '#0b60a5'
                document.getElementById('perId').style.color = '#ffffff'
            }
            if(e == 2){
                document.getElementById('retFaId').style.backgroundColor = '#0b60a5'
                document.getElementById('retId').style.color = '#ffffff'
            }
        },
        lostFocus(e){
            if(e == 1){
                document.getElementById('perFaId').style.backgroundColor = '#ffffff'
                document.getElementById('perId').style.color = '#545454'
            }
            if(e == 2){
                document.getElementById('retFaId').style.backgroundColor = '#ffffff'
                document.getElementById('retId').style.color = '#545454'
            }
        },
        toLogin() {
            this.$router.push({
                path: '/toLogin'
            });
        },
        confirmEvent() {
            var that = this
            var config = {
                method: 'post',
                url: '/a/logout',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                withCredentials: false
            };
            axios(config).then(function () {
                that.$confirm("退出成功", '消息', {
                    confirmButtonText: 'OK',
                    showCancelButton: false,
                    showClose: false,
                    closeOnClickModal: false,
                    closeOnHashChange: false,
                    closeOnPressEscape: false
                }).then(() => {
                    sessionStorage.removeItem('SESSION_ID')
                    sessionStorage.removeItem('USER_NAME')
                    sessionStorage.removeItem('user_id')
                    that.user_name_admin = '';
                    that.$router.push({
                        path: '/toLogin'
                    });
                });
            }).catch(function (error) {
                console.log(error);
            });
        }
    }
}
</script>

<style>

.per_body_all {
    position: relative;
    height: 989px;
    background-color: #eeecec;
    /* top: 20px; */
}

.per_body_all .per_body_all_side {
    position: relative;
    background-color: #eeecec;
    height: 969px;
    width: 1200px;
    top: 20px;
    left: 360px;
    /* border-radius: 0 25px 25px 0; */
}

.per_body_all .per_body_all_side .is_side {
    width: 240px;
    height: 969px;
    background-color: #fafafa;
    border-radius: 10px 0 0 0 ;
    float: left;
    box-shadow: 0px 5px 10px 0px rgba(108, 108, 108, 0.16);
}

.per_body_all .per_body_all_side .is_side .per_center_characters {
    position: relative;
    font-size: 36px;
    color: rgb(22, 22, 22);
    font-weight: 600;
    line-height: 1.2;
    width: 160px;
    left: 36px;
    top: 53px;
}

.per_body_all .per_body_all_side .is_side .list_button_position_identity {
    background-color: rgb(11, 96, 165);
    position: relative;
    width: 143px;
    height: 36px;
    z-index: 18;
    border-radius: 50px;
    left: 46px;
    top: 80px;
}

.list_button_position_identity_img {
    width: 17px;
    height: 14px;
    position: relative;
    background-image: url("../../assets/personImg/id_card_img_write.png");
    left: 20px;
    top: 10.5px;
}

.list_button_position_identity_img_modity {
    width: 17px;
    height: 14px;
    position: relative;
    background-image: url("../../assets/personImg/id_card_img_black.png");
    left: 20px;
    top: 10.5px;
}

.list_button_position_update_img {
    width: 15px;
    height: 15px;
    position: relative;
    background-image: url("../../assets/personImg/update_pwd_write.png");
    left: 20px;
    top: 10.5px;
}

.list_button_position_identity_characters {
    position: relative;
    color: white;
    font-size: 14px;
    text-decoration: none;
    height: 30px;
    width: 68px;
    left: 42px;
    top: -6px;
}

.per_body_all .per_body_all_side .is_side .list_button_position_updatepassword {
    background-color: rgb(11, 96, 165);
    position: relative;
    width: 143px;
    height: 36px;
    z-index: 18;
    border-radius: 50px;
    left: 46px;
    top: 90px;
}

.list_button_position_update_img_modify {
    width: 15px;
    height: 15px;
    position: relative;
    background-image: url("../../assets/personImg/update_pwd_black.png");
    left: 20px;
    top: 10.5px;
}

/* .list_button_position_identity_characters_modify {
    position: relative;
    
    font-size: 14px;
    text-decoration: none;
    height: 30px;
    width: 68px;
    left: 42px;
    top: -6px;
} */

.per_body_all .per_body_all_side .is_body_bg {
    width: 975px;
    height: 969px;
    position: relative;
    background-color: #ffffff;
    left: 225px;
    border-radius: 10px 10px 0 0 ;
    box-shadow: 0px 5px 10px 0px rgba(108, 108, 108, 0.16);
}

.per_body_all .per_body_all_side .is_body_bg .account_information_pos {
    font-size: 14px;
    color: rgb(83, 83, 73);
    line-height: 1.2;
    position: absolute;
    left: 49px;
    top: 61px;
}

.per_body_all .per_body_all_side .is_body_bg .pre_henggang {
    border-width: 2px;
    border-color: rgb(22, 38, 185);
    border-style: solid;
    background-color: rgb(51, 50, 50);
    position: absolute;
    height: 1px;
    width: 901px;

    left: 37px;
    top: 118px;
}

.per_body_all .per_body_all_side .is_body_bg .pre_shugang {
    background-color: rgb(11, 96, 166);
    width: 3px;
    height: 17px;
    position: absolute;
    left: 42px;
    top: 140px;
    z-index: 30;
}

.head .head_background_color .shugang {
    background-color: rgb(68, 68, 68);
    position: absolute;
    left: 663px;
    top: 22px;
    width: 2px;
    height: 53px;
    z-index: 25;
}

</style>