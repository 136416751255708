<template>
    <div class="total_register">
        <div class="head">
            <router-link to="/">
                <div class="head_img"></div>
                <div class="shugang"></div>
                <div class="tongxingzheng_zhuce">注册</div>
            </router-link>
            <div class="toLoginOrIndex">
                已有账户，
                <router-link to="/toLogin" class="toLogin">马上登录</router-link> &#8195;| &#8195;
                <router-link to="/" class="returnIndex">返回首页</router-link>
            </div>
        </div>
        <div class="other01">
            <div class="kuaidiImg"></div>
            <div class="registerBox">
                <div class="accountRegister">账户注册</div>
                <div class="font_style_forget_phone">手机号</div>
                <div class="short_shuxian"></div>
                <div class="font_style_forget_code">验证码</div>
                <div class="font_style_forget_set_pwd">设置密码</div>
                <el-form label-width="90px">
                    <el-input v-bind:class="{username01: old_pwd_class, isfail: phonefail}" @blur="handleChange(1)" @focus="fouceChange(1)" type="text" autocomplete="new-password" 
                    v-model="phoneOrEmail" placeholder="手机号" />
                    <el-select v-model="countryNumber" class="m-2 countryNumber" :teleported="false" placeholder="区号"
                        filterable>
                        <el-option v-for="count in countryList" :key="count.phone_code" :label="count.phone_code"
                            :value="count.phone_code" />
                    </el-select>
                    <el-button class="sentCode" type="primary" plain v-if="dataStatus == 0"
                        @click="sentCode(phoneOrEmail, countryNumber)">发送验证码</el-button>
                    <div class="countdown_time" v-if="dataStatus == 1">{{timeAll}}后重发</div>

                    <el-input v-bind:class="{codeMessage01: old_pwd_class, isfail: codefail}" @blur="handleChange(2)" @focus="fouceChange(2)" type="text" autocomplete="new-password" v-model="code"
                        placeholder="验证码" />
                    <el-input v-bind:class="{password01: old_pwd_class, isfail: pwdfail}" @blur="handleChange(3)" @focus="fouceChange(3)" autocomplete="new-password" type="password" v-model="pwd"
                        placeholder="密码" />
                    <el-checkbox class="accept" @change="checkAccept()" v-model="checked1"
                        label="我已阅读并接受&#8195;&#8195;&#8195;&#8197;&#8197;和" size="large" />
                    <a href="javaScript:void(0);" class="userAgreement" @click="getUserAgreement()">用户协议</a>
                    <a href="javaScript:void(0);" class="privacyPolicy" @click="getPrivacyPolicy()">隐私政策</a>
                    <el-button class="registerSubmit" type="primary" plain
                        @click="toRegister(phoneOrEmail, code, pwd)">注册</el-button>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>

import coun from '@/utils/NationalTelephoneAreaCode.json'
import axios from "@/utils/request";
import common from '@/utils/common';

export default {
    data() {
        return {
            phonefail: false,
            codefail: false,
            pwdfail: false,
            old_pwd_class: true,
            phoneOrEmail: '',
            code: '',
            pwd: '',
            countryNumber: '',
            checkStatus: '',
            countryList: coun,
            checked1: '',

            dataStatus: 0,
            timeAll: 120,
            timeDown: null
        }
    },
    methods: {
        handleChange(e){
            var that = this
            if(e == 1 && that.phoneOrEmail == ''){
                that.phonefail = true
            }
            if(e == 2 && that.code == ''){
                that.codefail = true
            }
            if(e == 3 && that.pwd == ''){
                that.pwdfail = true
            }
        },
        fouceChange(e){
            var that = this
            if(e == 1 && that.phoneOrEmail == ''){
                that.phonefail = false
            }
            if(e == 2 && that.code == ''){
                that.codefail = false
            }
            if(e == 3 && that.pwd == ''){
                that.pwdfail = false
            }
        },
        checkAccept() {
            if (this.checkStatus == '' || this.checkStatus == '取消选中') {
                this.checkStatus = '被选中'
            } else {
                this.checkStatus = '取消选中'
            }
        },
        setTimeAll(){
            this.timeDown = setInterval(() => {
                if(this.timeAll == 0){
                    this.dataStatus = 0
                    this.timeAll = 120
                    clearTimeout(this.timeDown)
                }
                this.timeAll = this.timeAll - 1
                console.log(this.timeAll)
            }, 1000);
        },
        sentCode(phoneOrEmail, countryNumber) {
            axios({
                methods: 'post',
                url: '/api/sms/sentSmsMessage?code=1&phone=' + phoneOrEmail + "&num=" + countryNumber,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if(response.code == '000001') {
                    this.dataStatus = 1
                    this.setTimeAll()
                }
                common.msg(response.message)
            }).catch(() => {
                common.msg('验证码发送失败，请联系管理员')
            });
        },
        toRegister(phoneOrEmail, code, pwd) {
            if (this.checkStatus == '' || this.checkStatus == '取消选中') {
                common.msg('请接受用户协议与隐私权限')
                return false;
            }
            axios({
                methods: 'post',
                url: '/api/front/toRegisterUser?phone=' + phoneOrEmail + "&code=" + code + "&pwd=" + pwd,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
                // timeout: 1000,
                // window.location = 'http://localhost:8081/toLogin';
            }).then((response) => {
                this.$confirm(response.message, '消息', {
                    confirmButtonText: 'OK',
                    showCancelButton: false,
                    showClose: false,
                    closeOnClickModal: false,
                    closeOnHashChange: false,
                    closeOnPressEscape: false
                }).then(() => {
                    if (response.code == "000001") {
                        common.rout('/toLogin')
                    }
                })
            }).catch(() => {
                common.msg('注册失败')
            });
        },
        getUserAgreement() {
            alert('点击用户协议')
        },
        getPrivacyPolicy() {
            alert('点击隐私政策')
        }
    },
    components: {
        coun
    }
}
</script>

<style>
#app .el-select {
    border: none;
    --el-select-input-focus-border-color: transparent;
}

.countryNumber .el-tooltip__trigger {
    width: 77px;
    position: relative;
}

.countryNumber .el-tooltip__trigger .el-input--suffix {
    width: 77px;
    border: none;
}

.countryNumber .el-tooltip__trigger .el-input--suffix .el-input__wrapper {
    position: relative;
    left: 0px;
    box-shadow: none !important;
}

.countryNumber .el-select__popper {
    position: absolute;
    left: 30px;
}

.username01 .el-input__wrapper .el-input__inner {
    text-indent: 143px;
}

.codeMessage01 .el-input__wrapper .el-input__inner {
    text-indent: 75px;
}

.password01 .el-input__wrapper .el-input__inner {
    text-indent: 75px;
}

.total_register {
    width: 1920px;
    height: 1080px;
}

.el-checkbox__input.is-checked+.el-checkbox__label {
    color: black;
}

.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: var(--el-checkbox-checked-bg-color);
    border-color: var(--el-checkbox-checked-input-border-color);
}

.total_register .head {
    width: 1920px;
    height: 91px;
}

.total_register .head .head_img {
    background-image: url('../assets/loginImg/head.png');
    background-size: cover;
    width: 288px;
    height: 71px;
    position: absolute;
    top: 9px;
    left: 360px;
}

.total_register .head .shugang {
    background-color: rgb(68, 68, 68);
    position: absolute;
    left: 663px;
    top: 22px;
    width: 2px;
    height: 53px;
    z-index: 25;
}

.tongxingzheng_zhuce {
    font-size: 36px;
    color: rgb(68, 68, 68);
    line-height: 1.2;
    position: absolute;
    left: 682.466px;
    top: 27.839px;
    z-index: 24;
}


.total_register .head .toLoginOrIndex {
    position: absolute;
    top: 43px;
    right: 300px;

    font-size: 15px;
    z-index: 16;
    line-height: 1.2;
}

.total_register .head .toLoginOrIndex .toLogin,.returnIndex {
    font-size: 15px;
    z-index: 16;
    line-height: 1.2;

    text-decoration: none;
    color: #3a5fe5;
}

/* .total_register .head .toLoginOrIndex  {
    font-size: 15px;
    z-index: 16;
    line-height: 1.2;

    text-decoration: none;
    color: #3a5fe5;
} */

.total_register .other01 {
    position: relative;
    width: 1920px;
    height: 989px;
    background-color: #eeecec;
}

.total_register .other01 .kuaidiImg {
    background-image: url('../assets/register/suyouzhuanyekuaidi.png');
    background-size: cover;
    position: absolute;
    left: 556px;
    top: 91px;
    width: 836px;
    height: 72.5px;
}

.total_register .other01 .registerBox {
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 5px 10px 0px rgba(108, 108, 108, 0.16);
    position: absolute;

    width: 586px;
    height: 520px;
    left: 661px;
    top: 228px;

}

.total_register .other01 .registerBox .accountRegister {
    font-size: 38px;
    width: 152px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    z-index: 17;
    font-weight: 600;
    position: absolute;
    left: 219px;
    top: 50px;
}

.total_register .other01 .registerBox .font_style_forget_phone {
    font-size: 14px;
    color: rgb(83, 83, 73);
    line-height: 1.2;
    font-weight: 600;
    position: absolute;
    top: 163px;
    left: 62px;
    z-index: 600;
}

.total_register .other01 .registerBox .font_style_forget_code {
    font-size: 14px;
    color: rgb(83, 83, 73);
    line-height: 1.2;
    font-weight: 600;
    position: absolute;
    top: 232px;
    left: 62px;
    z-index: 600;
}

.total_register .other01 .registerBox .countryNumber {
    position: absolute;
    top: 144px;
    left: 105px;
    z-index: 600;
}

.total_register .other01 .registerBox .short_shuxian {
    background-color: rgb(190, 190, 190);
    width: 1px;
    height: 29px;
    position: absolute;
    z-index: 600;
    left: 185px;
    top: 157px;
}

.total_register .other01 .registerBox .font_style_forget_set_pwd {
    font-size: 14px;
    color: rgb(83, 83, 73);
    line-height: 1.2;
    font-weight: 600;
    position: absolute;
    top: 299px;
    left: 62px;
    z-index: 600;
}

.total_register .registerBox .username01 {
    width: 499px;
    height: 54px;
    z-index: 40;
    position: absolute;
    left: 14px;
    top: 143px;
}

.total_register .registerBox .codeMessage01 {
    width: 499px;
    height: 54px;
    z-index: 40;
    position: absolute;
    left: 14px;
    top: 211px;
}

.total_register .registerBox .sentCode {
    height: 14px;
    width: 71px;
    background-color: white;
    color: #3a5fe5;
    font-size: 14px;
    z-index: 60;
    border: none;
    position: absolute;
    right: 66px;
    top: 164px;
}

.total_register .registerBox .countdown_time {
    height: 14px;
    width: 71px;
    background-color: white;
    color: #3a5fe5;
    font-size: 14px;
    z-index: 60;
    border: none;
    position: absolute;
    right: 66px;
    top: 163px;
}

.total_register .registerBox .password01 {
    width: 499px;
    height: 54px;
    z-index: 40;
    position: absolute;
    left: 14px;
    top: 279px;
}

.total_register .registerBox .accept {
    font-size: 14px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: absolute;
    top: 351px;
    left: 44px;
    width: 14px;
    height: 14px;
    font-weight: 500;
    letter-spacing: 2px;
}

.total_register .registerBox .registerSubmit {
    background-color: rgb(36, 42, 125);
    color: white;
    width: 499px;
    height: 54px;
    z-index: 40;
    position: absolute;
    left: 44px;
    top: 412px;
}

.total_register .registerBox .userAgreement {
    font-size: 14px;
    position: absolute;
    z-index: 14;
    line-height: 1.2;

    text-decoration: none;
    color: #3a5fe5;

    width: 80px;
    top: 350px;
    left: 167px;
}

.total_register .registerBox .privacyPolicy {
    font-size: 14px;
    position: absolute;
    z-index: 14;
    line-height: 1.2;

    text-decoration: none;
    color: #3a5fe5;

    width: 80px;
    top: 350px;
    left: 242px;
}
</style>