<template>
    <div class="total_forgot">
        <div class="head_forgot">
            <router-link to="/">
                <div class="head_img"></div>
            </router-link>
        </div>
        <div class="other01_forgot">
            <div class="kuaidiImg"></div>
            <div class="forgot_Box">
                <div class="forgot_account">忘记密码</div>
                <el-form label-width="90px">
                    <div class="font_style_forget">
                        <div class="font_style_forget_phone">手机号</div>
                        <div class="font_style_forget_code">验证码</div>
                        <div class="font_style_forget_set_pwd">设置密码</div>
                        <div class="font_style_forget_enter_pwd">确认密码</div>
                    </div>
                    <div class="short_shuxian"></div>
                    <el-input v-bind:class="{uName: old_pwd_class, isfail: namefail}" @blur="handleChange(1)" @focus="fouceChange(1)" type="text" autocomplete="new-password" v-model="phoneOrEmail"
                        placeholder="请输入注册手机号" />

                    <el-select v-model="countryNumber" class="m-2 countryNumber" :teleported="false" placeholder="区号"
                        filterable>
                        <el-option v-for="count in countryList" :key="count.phone_code" :label="count.phone_code"
                            :value="count.phone_code" />
                    </el-select>

                    <el-button class="sentCode" type="primary" plain v-if="dataStatus == 0"
                        @click="sentCode(phoneOrEmail, countryNumber)">发送验证码</el-button>
                    <div class="countdown_time_data" v-if="dataStatus == 1">{{timeAll}}后重发</div>

                    <el-input v-bind:class="{cMessage: old_pwd_class, isfail: msgfail}" @blur="handleChange(2)" @focus="fouceChange(2)" type="text" autocomplete="new-password" v-model="code"
                        placeholder="请输入验证码" />
                    <el-input v-bind:class="{set_password: old_pwd_class, isfail: setpwdfail}" @blur="handleChange(3)" @focus="fouceChange(3)" autocomplete="new-password" type="password" v-model="set_pwd"
                        placeholder="6-12位英文、数字" />
                    <el-input v-bind:class="{enter_password: old_pwd_class, isfail: entpwdfail}" @blur="handleChange(4)" @focus="fouceChange(4)" type="password" v-model="enter_pwd" placeholder="请再次输入密码" />
                    <el-button class="registerSubmit" type="primary" plain @click="toRegister(phoneOrEmail,code,set_pwd,enter_pwd)">完成设置</el-button>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>

import coun from '@/utils/NationalTelephoneAreaCode.json'
import axios from "@/utils/request";
import common from '@/utils/common';

export default {
    data() {
        return {
            old_pwd_class: true,
            namefail: false,
            msgfail: false,
            setpwdfail: false,
            entpwdfail: false,

            phoneOrEmail: '',
            code: '',
            set_pwd: '',
            enter_pwd: '',
            countryNumber: '',
            countryList: coun,

            dataStatus: 0,
            timeAll: 120,
            timeDown: null
        }
    },
    methods: {
        handleChange(e){
            var that = this
            if(e == 1 && that.phoneOrEmail == ''){
                that.namefail = true
            }
            if(e == 2 && that.code == ''){
                that.msgfail = true
            }
            if(e == 3 && that.set_pwd == ''){
                that.setpwdfail = true
            }
            if(e == 4 && that.enter_pwd == ''){
                that.entpwdfail = true
            }
        },
        fouceChange(e){
            var that = this
            if(e == 1 && that.phoneOrEmail == ''){
                that.namefail = false
            }
            if(e == 2 && that.code == ''){
                that.msgfail = false
            }
            if(e == 3 && that.set_pwd == ''){
                that.setpwdfail = false
            }
            if(e == 4 && that.enter_pwd == ''){
                that.entpwdfail = false
            }
        },
        setTimeAll(){
            this.timeDown = setInterval(() => {
                if(this.timeAll == 0){
                    this.dataStatus = 0
                    this.timeAll = 120
                    clearTimeout(this.timeDown)
                }
                this.timeAll = this.timeAll - 1
            }, 1000);
        },
        sentCode(phoneOrEmail,countryNumber) {
            var that = this
            if(that.phoneOrEmail == ''){
                common.msg('手机号不能为空')
                that.namefail = true
                return false
            }
            axios({
                methods: 'post',
                url: '/api/sms/sentSmsMessage?code=2&phone=' + phoneOrEmail + "&num=" + countryNumber,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if(response.code == '000001') {
                    this.dataStatus = 1
                    this.setTimeAll()
                }
                common.msg(response.message)
            }).catch(() => {
                common.msg('验证码发送失败，请联系管理员')
            });
        },
        toRegister(phoneOrEmail,code,set_pwd,enter_pwd) {
            var that = this
            if('' == phoneOrEmail){
                that.namefail = true
                return false
            }else if("" == set_pwd){
                that.msgfail = true;
                common.msg('验证码为空')
                return false
            }else if('' == code){
                that.setpwdfail = true;
                common.msg('设置密码为空')
                return false
            }else if ("" == enter_pwd){
                common.msg('确认密码为空')
                that.entpwdfail = true;
                return false
            }else if(set_pwd != enter_pwd){
                common.msg('两次密码输入不一致')
                that.setpwdfail = true;
                that.entpwdfail = true;
                return false
            }
            axios({
                methods: 'post',
                url: '/api/front/toUpdatePasswordMsg?phone=' + phoneOrEmail + "&code=" + code + "&pwd=" + set_pwd,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                that.$confirm(response.message, '消息', {
                    confirmButtonText: 'OK',
                    showCancelButton: false,
                    showClose: false,
                    closeOnClickModal: false,
                    closeOnHashChange: false,
                    closeOnPressEscape: false
                }).then(() => {
                    if (response.code == "000001") {
                        common.rout('/toLogin')
                    }
                })
            }).catch(() => {
                common.msg('注册失败')
            });
        }
    }
}
</script>

<style>

.uName .el-input__wrapper .el-input__inner {
    text-indent: 143px;
}

.cMessage .el-input__wrapper .el-input__inner {
    text-indent: 75px;
}

.set_password .el-input__wrapper .el-input__inner {
    text-indent: 75px;
}

.enter_password .el-input__wrapper .el-input__inner {
    text-indent: 75px;
}

.total_forgot .head_forgot {
    width: 1920px;
    height: 91px;
}

.total_forgot .head_forgot .head_img {
    background-image: url('../assets/loginImg/head.png');
    background-size: cover;
    width: 288px;
    height: 71px;
    position: absolute;
    top: 9px;
    left: 360px;
}


.total_forgot .other01_forgot {
    position: relative;
    width: 1920px;
    height: 989px;
    background-color: #eeecec;
}

.total_forgot .other01_forgot .kuaidiImg {
    background-image: url('../assets/register/suyouzhuanyekuaidi.png');
    background-size: cover;
    position: absolute;
    left: 556px;
    top: 91px;
    width: 836px;
    height: 72.5px;
}

.total_forgot .other01_forgot .forgot_Box {
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 5px 10px 0px rgba(108, 108, 108, 0.16);
    position: absolute;

    width: 586px;
    height: 544px;
    left: 661px;
    top: 228px;

}

.total_forgot .other01_forgot .countryNumber {
    position: absolute;
    top: 144px;
    left: 105px;
    z-index: 600;
}

.total_forgot .other01_forgot .short_shuxian {
    background-color: rgb(190, 190, 190);
    width: 1px;
    height: 29px;
    position: absolute;
    z-index: 600;
    left: 185px;
    top: 157px;
}

.total_forgot .other01_forgot .forgot_Box .forgot_account {
    font-size: 38px;
    width: 152px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    z-index: 17;
    font-weight: 600;
    position: absolute;
    left: 219px;
    top: 50px;
}

.total_forgot .other01_forgot .forgot_Box .font_style_forget {
    font-size: 14px;
    color: rgb(83, 83, 73);
    line-height: 1.2;
    font-weight: 600;
}

.total_forgot .other01_forgot .forgot_Box .font_style_forget .font_style_forget_phone {
    position: absolute;
    top: 163px;
    left: 62px;
    z-index: 600;
}

.total_forgot .other01_forgot .forgot_Box .font_style_forget .font_style_forget_code {
    position: absolute;
    top: 232px;
    left: 62px;
    z-index: 600;
}

.total_forgot .other01_forgot .forgot_Box .font_style_forget .font_style_forget_set_pwd {
    position: absolute;
    top: 299px;
    left: 62px;
    z-index: 600;
}

.total_forgot .other01_forgot .forgot_Box .font_style_forget .font_style_forget_enter_pwd {
    position: absolute;
    top: 367px;
    left: 62px;
    z-index: 600;
}

.total_forgot .forgot_Box .uName {
    width: 499px;
    height: 54px;
    z-index: 40;
    position: absolute;
    left: 14px;
    top: 143px;
}

.total_forgot .forgot_Box .cMessage {
    width: 499px;
    height: 54px;
    z-index: 40;
    position: absolute;
    left: 14px;
    top: 211px;
}

.total_forgot .forgot_Box .sentCode {
    height: 14px;
    width: 71px;
    background-color: white;
    color: #3a5fe5;
    font-size: 14px;
    z-index: 60;
    border: none;
    position: absolute;
    right: 66px;
    top: 164px;
}

.total_forgot .forgot_Box .countdown_time_data {
    height: 14px;
    width: 71px;
    background-color: white;
    color: #3a5fe5;
    font-size: 14px;
    z-index: 80;
    border: none;
    position: absolute;
    right: 66px;
    top: 163px;
}

.total_forgot .forgot_Box .set_password {
    width: 499px;
    height: 54px;
    z-index: 40;
    position: absolute;
    left: 14px;
    top: 279px;
}

.total_forgot .forgot_Box .enter_password {
    width: 499px;
    height: 54px;
    z-index: 40;
    position: absolute;
    left: 14px;
    top: 346px;
}

.total_forgot .forgot_Box .accept {
    font-size: 14px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: absolute;
    top: 351px;
    left: 44px;
    width: 14px;
    height: 14px;
    font-weight: 500;
    letter-spacing: 2px;
}

.total_forgot .forgot_Box .registerSubmit {
    background-color: rgb(36, 42, 125);
    color: white;
    width: 499px;
    height: 54px;
    z-index: 40;
    position: absolute;
    left: 44px;
    top: 434px;
}

.total_forgot .forgot_Box .userAgreement {
    font-size: 14px;
    position: absolute;
    z-index: 14;
    line-height: 1.2;

    text-decoration: none;
    color: #3a5fe5;

    width: 80px;
    top: 350px;
    left: 167px;
}

.total_forgot .forgot_Box .privacyPolicy {
    font-size: 14px;
    position: absolute;
    z-index: 14;
    line-height: 1.2;

    text-decoration: none;
    color: #3a5fe5;

    width: 80px;
    top: 350px;
    left: 242px;
}
</style>