import axios from "axios";

axios.interceptors.response.use(res => {
    if (res.status >= 300 && res.status < 400 && res.headers.location) {
        return axios.get(res.headers.location).then(response => {
            return response.data;
        });
    }
    return res.data;
}, err => {
    return Promise.reject(err);
});

axios.interceptors.request.use(config => {
    config.baseURL = '/suyou';
    config.withCredentials= false;
    return config;
});

export default axios;
