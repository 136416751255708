<template>
    <HeadView />
    <div class="total">
        <div class="head_down01">
            <div class="notiveImg"></div>
        </div>
        <div class="order_new_write">
            <div class="unknowImg_new_write"></div>
            <div class="wenziPosition_new_write">
                <div class="wenziStyle_new_write">暂时没有内容</div>
                <router-link to="/" class="returnIndex_new_write">返回首页</router-link> 
            </div>
        </div>
        <div class="downLogoPosition_new_write">
            <TailView />
        </div>
    </div>
</template>

<script>
import HeadView from '@/components/comm/HeadView';
import TailView from '@/components/comm/TailView';
export default{
    components: {
        HeadView,
        TailView
    }
}
</script>

<style scoped>

.total {
    width: 1920px;
    height: 1135px;
}

.total .head_down01 {
    top: 91px;
    width: auto;
    opacity: 1;
}

.total .head_down01 .notiveImg {
    background-image: url('../assets/noticeImg/newsBackground.png');
    background-size: contain;
    height: 288px;
    width: 1920px;
}

.total .order_new_write {
    position: absolute;
}

.total .order_new_write .wenziPosition_new_write {
    position: relative;
    height: 100px;
    width: 100px;
    position: relative;
    top: 100px;
}

.total .order_new_write .wenziPosition_new_write .wenziStyle_new_write {
    font-size: 16px;
    color: rgb(0, 0, 0);
    line-height: 1.2;
    text-align: center;
    position: absolute;
    top: 442px;
    left: 915px;
    width: 100px;
}

.total .order_new_write .unknowImg_new_write {
    background-image: url('../assets/noticeImg/unKnowImg.png');
    background-size: contain;
    width: 473px;
    height: 332px;
    position: absolute;
    top: 197px;
    left: 724.7px;
}

.total .order_new_write .returnIndex_new_write {
    font-size: 15px;
    z-index: 16;
    line-height: 1.2;
    
    text-decoration: none;
    color: #3a5fe5;

    position: absolute;
    width: 60px;
    top: 462px;
    left: 933px;
}

.total .downLogoPosition_new_write {
    position: absolute;
    width: 1920px;
    top: 256px;
}

</style>