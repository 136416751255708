<template>
    <HeadView />
    <div class="total">
        <div class="head_down01">
            <div class="notiveImg"></div>
        </div>
        <div class="other">
            <div class="changtiao">
                <div class="wenzi">最新公告</div>
            </div>
            <div class="totalPicture10">
                <div class="picture10" v-for="item of noticeList">
                    <router-link :to="{path: '/toNoticeDetails',query: {id: item.id}}">
                        <div class="pictureUp">
                            <div class="pictureUp_img_Notice">
                                <!-- ../assets/indexImg/announcementImage.png -->
                                <img :src="[[item.img]]" class="pictureUp_img_Notice_img">
                            </div>
                        </div>
                        <div class="pictureDown">
                            <div class="announcementTopic">
                                {{ item.total }}
                            </div>
                            <div class="announcementContent" v-html="[[item.description]]">
                            </div>
                            <div class="announcementTime">
                                {{ item.createDate }}
                            </div>
                            <div class="arrow0012"></div>
                        </div>
                    </router-link>
                </div>
            </div>

            <div class="announcementPosition">
                <div class="announcement">公告</div>
            </div>
            <div class="announcementList">
                <div v-for="item of list">
                    <router-link :to="{path: '/toNoticeDetails',query: {id: item.id}}">
                        <div class="pageInfo">
                            <div class="announcementImg">
                                <img class="announcementImgMsg" :src="[[localUrl+item.image]]">
                            </div>
                            <div class="announcementTitle">{{ item.title }}</div>
                            <div class="announcementIntroduction">{{ item.description.slice(0,120) + "..." }}</div>
                            <div class="announcementDate">
                                {{ item.createDate }}
                            </div>
                            <div class="henggang"></div>
                        </div>
                    </router-link>
                </div>
                <div class="pageInfoList">
                    <div class="yema_style">6条/页 共{{ page }}页 共{{ tiao }}条</div>
                    <el-pagination class="fenye_style" background :page-size="6" layout="prev, pager, next, jumper"
                        :total="tiao" @current-change="getPageNum" />
                    <el-button class="weiye_button" @click="clickNoticeGetMessage(page,1)">尾页</el-button>
                    <el-button class="tiaozhuan_button">跳转</el-button>
                </div>
            </div>
        </div>
        <div class="tail_position_notice">
            <TailView />
        </div>

    </div>
</template>

<script>
import HeadView from '@/components/comm/HeadView';
import TailView from '@/components/comm/TailView';
import axios from "@/utils/request";
import common from "@/utils/common"

export default {
    data() {
        return {
            lastPage: '#ffffff',
            page: '1',
            tiao: 1,
            pagenum: '1',
            localUrl: common.GlobalUrl,
            list: [],
            noticeList: []
        }
    },
    created() {
        var that = this;
        that.clickNoticeGetMessage();
        that.getHotNotice();
    },
    methods: {
        toLogin() {
            common.rout('/toLogin')
        },
        getHotNotice() {
            axios({
                methods: 'post',
                url: '/api/noticeAndNews/clickNoticeGetMessage?categoryId=ff35bfc6901247b0bb0447a4982be54b',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                var list = []
                for (var i = 0; i < 3; i++) {
                    var obj = {
                        id: response.data.list[i].id,
                        total: response.data.list[i].title,
                        description: response.data.list[i].articleData.content.slice(0, 40) + "...",
                        createDate: response.data.list[i].createDate,
                        img: common.GlobalUrl + response.data.list[i].image
                    }
                    list[i] = obj;
                }
                this.noticeList = list
            });
        },
        getPageNum(val) {
            var that = this
            var doc = document.querySelector('.pageInfoList .is-active');
            if(doc == null) {
                common.msg('系统出现错误,请刷新后再试')
                return false;
            }
            doc.classList.remove('is-active')
            that.clickNoticeGetMessage(val)
        },
        clickNoticeGetMessage(pageNo,e) {
            var that = this
            if (pageNo == undefined) {
                pageNo = 1
            }
            axios({
                methods: 'post',
                url: '/api/noticeAndNews/clickNoticeGetMessage?categoryId=ff35bfc6901247b0bb0447a4982be54b&pageSize=6&pageNo=' + pageNo,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                that.page = Number((response.data.count / 6).toString().split('.')[0]) + 1
                that.tiao = response.data.count
                that.list = response.data.list
                if(e == 1){
                    var entity = document.querySelector('.pageInfoList .is-active');
                    if(entity == null){
                        common.msg('系统出现问题，请刷新后再试')
                        return false
                    }
                    entity.classList.remove("is-active");
                    var list = document.querySelectorAll('.pageInfoList li')
                    list[list.length - 1].classList.add('is-active')
                }
            });
        }
    },
    components: {
        HeadView,
        TailView
    }
}
</script>

<style>

.total .other .announcementList .pageInfoList .weiye_button {
    position: absolute !important;
    left: 580px !important;
    top: -1px !important;
}

.total .other .announcementList .pageInfoList .tiaozhuan_button {
    position: absolute !important;
    left: 678px !important;
    top: -1px !important;
}

.pageInfoList .fenye_style .is-last .is-in-pagination {
    position: absolute !important;
    left: 377px !important;
    top: -1px !important;
}

.btn-next {
    position: absolute !important;
    left: 317px !important;
}

.el-pagination__classifier {
    display: none;
}

.total {
    width: 1920px;
    height: 2256px;
}

#app .el-pagination.is-background .el-pager li.is-active {
    background-color: #0b60a6;
    color: var(--el-color-white);
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
    margin: 0 4px;
    background-color: white;
    color: #c3c3c3;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(238, 236, 236);
}

#app .el-pagination.is-background .el-pager li {
    margin: 0 4px;
    background-color: white;
}

#app .is-first {
    background-color: white;
}

#app .btn-next {
    background-color: white;
}

.el-pager .el-icon {
    display: none;
}

.total .tail_position_notice {
    position: relative;
    width: 1920px;
    height: 0px;
    top: 75px;
    z-index: 600;
}

.total .head_down01 {
    height: 288px;
    top: 91px;
    width: auto;
    opacity: 1;
}

.total .head_down01 .notiveImg {
    background-image: url('../assets/noticeImg/noticeBackground.png');
    background-size: contain;
    height: 288px;
    width: 1920px;
}

.total .other {
    width: auto;
    height: 820px;
}

.total .changtiao {
    background-color: rgb(238, 236, 236);
    position: absolute;
    left: 359.4px;
    top: 395.3px;
    width: 1201px;
    height: 63px;
}

.total .changtiao .wenzi {
    font-size: 26px;
    color: #1b2649;
    line-height: 1.2;
    font-weight: 600;

    position: absolute;
    top: 14px;
    left: 12px;

}

.total .other .totalPicture10 {
    width: auto;
    height: 495px;
    position: relative;
    top: 119px;
    padding: 0 340.5px;
}

.total .other .totalPicture10 .picture10 {
    width: 352px;
    height: 350px;

    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    position: relative;
    float: left;
    top: 0px;
    
    padding: 0 30.5px;
}

.total .other .pictureUp {
    width: 352px;
    height: 350px;
    border-radius: 6px 6px 0px 0px;
    opacity: 1;
    position: absolute;
}

.total .other .pictureUp .pictureUp_img_Notice {
    /* background-image: url('../assets/indexImg/announcementImage.png'); */
    background-size: contain;
    width: 352px;
    height: 350px;
}

.total .other .pictureUp .pictureUp_img_Notice .pictureUp_img_Notice_img {
    width: 352px;
    height: 350px;
}

.total .other .pictureDown {
    box-shadow: 0px 5px 10px 0px rgba(108, 108, 108, 0.16);
    position: relative;
    width: 352px;
    height: 143px;
    border-radius: 0px 0px 6px 6px;
    position: absolute;
    top: 350px;
}

.total .other .announcementTopic {
    font-size: 16px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: absolute;

    top: 30px;
    left: 15px;
    z-index: 46;
    font-weight: 600;
}

.total .other .announcementContent {
    font-size: 14px;
    color: rgb(111, 111, 111);
    line-height: 1.2;
    position: absolute;
    z-index: 47;
    width: 330px;

    text-align: left;
    top: 61px;
    left: 15px;
    z-index: 46;
}

.total .other .announcementTime {
    font-size: 14px;
    color: rgb(195, 195, 195);
    line-height: 1.2;
    position: absolute;
    z-index: 48;
    top: 114px;
    bottom: 12px;
    left: 15px;
    z-index: 46;
}

.total .other .arrow0012 {
    background-image: url('../assets/indexImg/arrow.png');
    background-size: contain;
    width: 30px;
    height: 14px;
    position: absolute;
    z-index: 48;

    bottom: 15px;
    z-index: 46;
    left: 290px;
}

.total .totalPicture10 .picture11 {
    width: 352px;
    height: 510px;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;

    position: absolute;
    top: 92px;
    left: 784px;
}

.total .totalPicture10 .picture12 {
    width: 352px;
    height: 510px;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;

    position: absolute;
    top: 92px;
    left: 1192px;
}

.total .other .announcementPosition {
    background-color: rgb(238, 236, 236);
    position: absolute;
    left: 359px;
    top: 1030px;
    width: 1201px;
    height: 63px;
    z-index: 63;
}

.total .other .announcementPosition .announcement {
    font-size: 24px;
    color: #1b2649;
    line-height: 1.2;
    font-weight: 600;

    position: absolute;
    top: 16px;
    left: 12px;
}

.total .other .announcementList {
    width: 1201px;
    height: 500px;
    z-index: 80;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    top: 1690px;

}

.total .other .announcementList .pageInfo {
    width: 1201px;
    height: 138px;
    opacity: 1;
    top: 6px;
    position: relative;
}

.total .announcementImg {
    position: absolute;
    left: 17px;
    top: 6px;
}

.total .announcementImgMsg {
    width: 100px;
    height: 100px;
    position: absolute;
}

.total .announcementTitle {
    font-size: 16px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: absolute;
    font-weight: 600;
    top: 13px;
    left: 172px;
}

.total .announcementIntroduction {
    width: 1040px;
    height: 38px;
    position: absolute;
    font-size: 14px;
    text-align: left;
    color: rgb(111, 111, 111);
    line-height: 1.2;
    left: 172px;
    top: 40px;
}

.total .announcementDate {
    font-size: 14px;
    color: rgb(195, 195, 195);
    line-height: 1.2;
    position: absolute;
    left: 172px;
    top: 83px;
}

.announcementList .pageInfo .henggang {
    background-color: rgb(238, 236, 236);
    position: absolute;
    top: 124px;
    width: 1171px;
    height: 1px;
    z-index: 87;
}


.total .other .announcementList .pageInfoList {
    position: absolute;
    height: 200px;
    top: 865px;
    left: 240px;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 90;
}

.demo-pagination-block .el-pagination .is-last .el-pagination__goto {
    display: none;
}
</style>

