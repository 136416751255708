<template>
    <HeadView />
    <div class="total">
        <div class="head_down01">
            <div class="notiveImg_notice"></div>
        </div>
        <div class="other">
            <div class="changtiao">
                <div class="wenzi">热点新闻</div>
            </div>
            <div class="totalPicture100">
                <div class="picture100" v-for="item of newsList">
                    <router-link :to="{path: '/toNewsDetails',query: {id: item.id}}">
                        <div class="pictureUp">
                            <div class="pictureUp_img_News">
                                <img :src="[[item.img]]" class="pictureUp_img_News_img">
                            </div>
                        </div>
                        <div class="pictureDown">
                            <div class="announcementTopic">
                                {{ item.total }}
                            </div>
                            <div class="announcementContent" v-html="[[item.description]]">
                            </div>
                            <div class="announcementTime">
                                {{ item.createDate }}
                            </div>
                            <div class="arrow0012"></div>
                        </div>
                    </router-link>
                </div>
            </div>

            <div class="announcementPosition">
                <div class="announcement">新闻</div>
            </div>
            <div class="announcementList">
                <div v-for="item of list">
                    <router-link :to="{path: '/toNewsDetails',query: {id: item.id}}">
                        <div class="pageInfo">
                            <div class="announcementImg">
                                <img class="announcementImgMsg" :src="[[localUrl+item.image]]">
                            </div>
                            <div class="announcementTitle">{{ item.title }}</div>
                            <div class="announcementIntroduction">{{ item.description.slice(0,120) + "..." }}</div>
                            <div class="announcementDate">
                                {{ item.createDate }}
                            </div>
                            <div class="henggang"></div>
                        </div>
                    </router-link>
                </div>
                <div class="pageInfoList">
                    <div class="yema_style">6条/页 共{{ page }}页 共{{ tiao }}条</div>
                    <el-pagination class="fenye_style" background :page-size="6" layout="prev, pager, next, jumper"
                        :total="tiao" @current-change="getPageNum" />
                    <el-button class="weiye_button" @click="clickNoticeGetMessage(page,1)">尾页</el-button>
                    <el-button class="tiaozhuan_button">跳转</el-button>
                </div>
            </div>
        </div>
        <div class="tail_position_news">
            <TailView />
        </div>

    </div>
</template>

<script>
import HeadView from '@/components/comm/HeadView';
import TailView from '@/components/comm/TailView';
import axios from "@/utils/request";
import common from "@/utils/common"

export default {
    data() {
        return {
            page: '',
            tiao: 1,
            pagenum: '1',
            localUrl: common.GlobalUrl,
            list: [],
            newsList: [],
            currentPage: ''
        }
    },
    created() {
        var that = this;
        that.clickNoticeGetMessage();
        that.getHotNotice();
    },
    methods: {
        toLogin() {
            common.rout('/toLogin')
        },
        getHotNotice() {
            var that = this;
            axios({
                methods: 'post',
                url: '/api/noticeAndNews/clickNoticeGetMessage?categoryId=ddff81cde79f4ca9949f19a83a66ac1d&pageSize=3',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                // console.log("---------------------")
                // console.log(response)
                var dataList = response.data.list;
                const list = [];
                for (var i = 0; i < dataList.length; i++) {
                    var obj = {
                        id: response.data.list[i].id,
                        total: response.data.list[i].title,
                        description: response.data.list[i].articleData.content.slice(0, 40) + "...",
                        createDate: response.data.list[i].createDate,
                        img: common.GlobalUrl + response.data.list[i].image
                    }
                    // console.log(obj);
                    list.push(obj);
                }
                // console.log("---------------------")
                // console.log(list[2]);
                that.newsList = list
                // that.$forceUpdate();
            });
        },
        getPageNum(val) {
            var that = this
            // that.currentPage = val
            // var list = document.getElementsByClassName('number')
            // list[that.page - 2].setAttribute('class','number')
            // console.log(list[that.page - 2]);
            var doc = document.querySelector('.pageInfoList .is-active');
            if(doc == null) {
                common.msg('系统出现错误,请刷新后再试')
                return false;
            }
            doc.classList.remove('is-active')
            doc.setAttribute('aria-current','false');
            // var list = document.querySelectorAll('.pageInfoList li');
            that.clickNoticeGetMessage(val)
        },
        clickNoticeGetMessage(pageNo,e) {
            var that = this
            if (pageNo == undefined) {
                pageNo = 1
            }
            axios({
                methods: 'post',
                url: '/api/noticeAndNews/clickNoticeGetMessage?categoryId=ddff81cde79f4ca9949f19a83a66ac1d&pageSize=6&pageNo=' + pageNo,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                that.page = Number((response.data.count / 6).toString().split('.')[0]) + 1
                that.tiao = response.data.count
                that.list = response.data.list
                if(e == 1){
                    var doc = document.querySelector('.pageInfoList .is-active');
                    if(doc == null) {
                        common.msg('系统出现问题，请刷新后再试')
                        return false
                    } 
                    doc.classList.remove('is-active');
                    doc.setAttribute('aria-current','false');
                    var list = document.querySelectorAll('.pageInfoList li');
                    list[list.length-1].classList.add("is-active");
                    list[list.length-1].setAttribute('aria-current','true');
                    document.querySelector('.el-input__inner').setAttribute('value',list.length)
                    // console.log(document.querySelector('.el-input__inner'))
                    
                    // console.log(list)
                    // console.log(list)
                    // if(that.currentPage == ''){
                    //     that.currentPage = 1
                    // }
                    // list[list.length - 1].setAttribute('class','is-active number')
                    // list[that.currentPage - 1].setAttribute('class','number')
                    // if(list[that.currentPage - 1] != undefined){
                    //     console.log(that.currentPage-1)
                    //     list[that.currentPage - 1].setAttribute('class','number')
                    //     console.log(list)
                    // }
                }
            });
        }
    },
    components: {
        HeadView,
        TailView
    }
}
</script>

<style>
.el-pager .el-icon {
    display: none;
}

.total {
    width: 1920px;
    height: 1356px;
}

.total .tail_position_news {
    position: relative;
    height: 0px;
    width: 1920px;
    top: 75px;
    z-index: 600;
}

.total .head_down01 {
    top: 91px;
    width: auto;
    height: 288px;
    opacity: 1;
}

.total .head_down01 .notiveImg_notice {
    background-image: url('../assets/noticeImg/newsBackground.png');
    background-size: contain;
    height: 288px;
    width: 1920px;
}

.total .changtiao {
    background-color: rgb(238, 236, 236);
    position: absolute;
    left: 359.4px;
    top: 395.3px;
    width: 1201px;
    height: 63px;
}

.total .changtiao .wenzi {
    font-size: 26px;
    color: #1b2649;
    line-height: 1.2;
    font-weight: 600;

    position: absolute;
    top: 14px;
    left: 12px;

}

.total .other {
    width: auto;
    height: 820px;
}

.total .other .totalPicture100 {
    width: auto;
    height: 495px;
    position: relative;
    top: 119px;
    padding: 0 340.5px;
}

.total .other .totalPicture100 .picture100 {
    width: 352px;
    height: 350px;

    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    position: relative;
    float: left;
    top: 0px;
    
    padding: 0 30.5px;
}

.total .other .pictureUp {
    width: 352px;
    height: 350px;
    border-radius: 6px 6px 0px 0px;
    opacity: 1;
    position: absolute;
}

.total .other .pictureUp .pictureUp_img_News {
    /* background-image: url('../assets/indexImg/announcementImage.png'); */
    background-size: contain;
    width: 352px;
    height: 350px;
}

.total .other .pictureUp .pictureUp_img_News .pictureUp_img_News_img {
    width: 352px;
    height: 350px;
}

.total .other .pictureDown {
    box-shadow: 0px 5px 10px 0px rgba(108, 108, 108, 0.16);
    position: relative;
    width: 352px;
    height: 143px;
    border-radius: 0px 0px 6px 6px;
    position: absolute;
    top: 350px;
}

.total .other .announcementTopic {
    font-size: 16px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: absolute;

    top: 30px;
    left: 15px;
    z-index: 46;
    font-weight: 600;
}

.total .other .announcementContent {
    font-size: 14px;
    color: rgb(111, 111, 111);
    line-height: 1.2;
    position: absolute;
    z-index: 47;
    width: 330px;

    text-align: left;
    top: 61px;
    left: 15px;
    z-index: 46;
}

.total .other .announcementTime {
    font-size: 14px;
    color: rgb(195, 195, 195);
    line-height: 1.2;
    position: absolute;
    z-index: 48;
    top: 114px;
    bottom: 12px;
    left: 15px;
    z-index: 46;
}

.total .other .arrow {
    background-image: url('../assets/indexImg/arrow.png');
    background-size: contain;
    width: 33px;
    height: 14px;
    position: absolute;
    z-index: 48;

    bottom: 15px;
    z-index: 46;
    left: 290px;
}

.total .totalPicture10 .picture11 {
    width: 352px;
    height: 510px;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;

    position: absolute;
    top: 92px;
    left: 784px;
}

.total .totalPicture10 .picture12 {
    width: 352px;
    height: 510px;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;

    position: absolute;
    top: 92px;
    left: 1192px;
}

.total .other .announcementPosition {
    background-color: rgb(238, 236, 236);
    position: absolute;
    left: 359px;
    top: 1030px;
    width: 1201px;
    height: 63px;
    z-index: 63;
}

.total .other .announcementPosition .announcement {
    font-size: 24px;
    color: #1b2649;
    line-height: 1.2;
    font-weight: 600;

    position: absolute;
    top: 16px;
    left: 12px;
}

.total .other .announcementList {
    width: 1201px;
    height: 1150px;
    z-index: 80;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    top: 1690px;

}

.total .other .announcementList .pageInfo {
    width: 1201px;
    height: 138px;
    opacity: 1;
    top: 6px;
    position: relative;
}

.total .announcementImg {
    position: absolute;
    left: 17px;
    top: 6px;
}

.total .announcementImgMsg {
    width: 100px;
    height: 100px;
    position: absolute;
}

.total .announcementTitle {
    font-size: 16px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: absolute;
    font-weight: 600;
    top: 13px;
    left: 172px;
}

.total .announcementIntroduction {
    width: 1040px;
    height: 38px;
    position: absolute;
    font-size: 14px;
    text-align: left;
    color: rgb(111, 111, 111);
    line-height: 1.2;
    left: 172px;
    top: 40px;
}

.total .announcementDate {
    font-size: 14px;
    color: rgb(195, 195, 195);
    line-height: 1.2;
    position: absolute;
    left: 172px;
    top: 83px;
}

.announcementList .pageInfo .henggang {
    background-color: rgb(238, 236, 236);
    position: absolute;
    top: 124px;
    width: 1171px;
    height: 1px;
    z-index: 87;
}

.total .other .announcementList .pageInfoList {
    position: absolute;
    top: 865px;
    left: 230px;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 90;
}

.total .other .announcementList .pageInfoList .yema_style {
    font-size: 14px;
    color: rgb(11, 96, 166);
    line-height: 1.2;
    position: absolute;
    top: 8px;
    left: 80px;
    z-index: 112;

}

.total .other .announcementList .pageInfoList .fenye_style {
    position: absolute;
    top: 0px;
    left: 220px;
}

span.el-pagination__goto {
    display: none;
}

.el-input__wrapper {
    position: relative;
    left: 29px;
    top: 1px;
}

.total .other .announcementList .pageInfoList .weiye_button {
    font-size: 14px;
    color: rgb(195, 195, 195);
    border-width: 1px;
    border-color: rgb(238, 236, 236);
    width: 39px;
    height: 34px;
    border-style: solid;
    line-height: 1.2;

}

.total .other .announcementList .pageInfoList .tiaozhuan_button {
    font-size: 14px;
    color: rgb(255, 255, 255);
    line-height: 1.2;
    background-color: rgb(11, 96, 166);
    width: 44px;
    height: 34px;
}
</style>

