<template>
    <HeadView />
    <div class="total">
        <div class="head_down01">
            <div class="serviceBackground"></div>
        </div>
        <div class="other">
            <div class="mapPositong"></div>
            <div class="shape" @mouseenter="mouseenter_all(num = 1)" @mouseleave="mouseleave_all(num = 1)">
                <div class="upPart" :style="{ background : bgColor_kuaidi }">
                    <div class="kuaidi"></div>
                </div>
                <div class="lowerPart">
                    <div class="mingcheng">标准快递</div>
                    <div class="duangang"></div>
                    <div class="jieshiwenzi">此处输入服务介绍，此处输入服务介绍此处输入服务介绍此处输入服务介绍，此处输入服务介绍此处输入服务介绍此处输入服务介绍此处输入服</div>
                </div>
            </div>
            <div class="shape1" @mouseenter="mouseenter_all(num = 2)" @mouseleave="mouseleave_all(num = 2)">
                <div class="upPart" :style="{ background: bgColor_cangcu }">
                    <div class="cangchu"></div>
                </div>
                <div class="lowerPart">
                    <div class="mingcheng">仓储服务</div>
                    <div class="duangang"></div>
                    <div class="jieshiwenzi">此处输入服务介绍，此处输入服务介绍此处输入服务介绍此处输入服务介绍，此处输入服务介绍此处输入服务介绍此处输入服务介绍此处输入服</div>
                </div>
            </div>
            <div class="shape2" @mouseenter="mouseenter_all(num = 3)" @mouseleave="mouseleave_all(num = 3)">
                <div class="upPart" :style="{ background: bgColor_zengzhi }">
                    <div class="zengzhi"></div>
                </div>
                <div class="lowerPart">
                    <div class="mingcheng">增值服务</div>
                    <div class="duangang"></div>
                    <div class="jieshiwenzi">此处输入服务介绍，此处输入服务介绍此处输入服务介绍此处输入服务介绍，此处输入服务介绍此处输入服务介绍此处输入服务介绍此处输入服</div>
                </div>
            </div>
        </div>
        <div class="downLogoPosition_service">
            <TailView />
        </div>
    </div>
</template>

<script>
import HeadView from '@/components/comm/HeadView';
import TailView from '@/components/comm/TailView';
export default {
    data() {
        return {
            bgColor_kuaidi: '#1b2649',
            bgColor_cangcu: '#1b2649',
            bgColor_zengzhi: '#1b2649'
        }
    },
    methods: {
        mouseenter_all(num) {
            if(num == 1){
                this.bgColor_kuaidi = '#0b60a6'
            }
            if(num == 2){
                this.bgColor_cangcu = '#0b60a6'
            }
            if(num == 3){
                this.bgColor_zengzhi = '#0b60a6'
            }
        },
        mouseleave_all(num){
            if(num == 1){
                this.bgColor_kuaidi = '#1b2649'
            }
            if(num == 2){
                this.bgColor_cangcu = '#1b2649'
            }
            if(num == 3){
                this.bgColor_zengzhi = '#1b2649'
            }
        },
    },
    components: {
        HeadView,
        TailView
    }
}
</script>

<style scoped>
.total {
    width: 1920px;
    height: 0px;
}

.total .head_down01 {
    top: 91px;
    height: 288px;
    width: auto;
    opacity: 1;
}

.total .head_down01 .serviceBackground {
    background-image: url('../assets/noticeImg/serviceBackground.png');
    background-size: cover;
    position: relative;
    height: 288px;
    width: 1920px;
}

.total .other {
    width: 100%;
    height: 0px;
}

.total .other .mapPositong {
    background-image: url('../assets/indexImg/map.png');
    background-size: cover;
    width: 874px;
    height: 523px;
    position: absolute;
    top: 402px;
    left: 472px;
}

.total .other .lowerPart {
    background-color: rgb(255, 254, 255);
    box-shadow: 0px 5px 10px 0px rgba(108, 108, 108, 0.16);
    border-radius: 10px;
    width: 352px;
    height: 330px;
    position: relative;
}

.mingcheng {
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    font-size: calc(100vw * 16 / 1920);
    color: rgb(22, 22, 22);
    line-height: 1.2;
}

.duangang {
    background-color: rgb(11, 96, 166);
    position: absolute;
    width: 114px;
    height: 3px;

    top: -120px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.jieshiwenzi {
    font-size: calc(100vw * 16 / 1920);
    color: rgb(22, 22, 22);
    line-height: 1.5;
    text-align: left;
    width: 310px;
    height: 160px;
    z-index: 31;

    position: absolute;
    top: 120px;
    left: 20px;

}

.total .other .upPart {
    width: 70px;
    height: 70px;
    background-color: #1b2649;
    box-shadow: 0px 5px 10px 0px rgba(108, 108, 108, 0.16);
    border-radius: 50%;
    position: relative;
    z-index: 60;
    top: 35px;
    left: 140px;
}

.total .other .upPart .kuaidi {
    background-image: url('../assets/sever/kuaidi.png');
    background-size: cover;
    width: 41px;
    height: 35px;
    position: absolute;
    top: 17px;
    left: 15px;
}

.total .other .upPart .zengzhi {
    background-image: url('../assets/sever/zengzhi.png');
    background-size: cover;
    width: 30px;
    height: 37px;
    position: absolute;
    top: 16px;
    left: 20px;
}

.total .other .upPart .cangchu {
    background-image: url('../assets/sever/cangchu.png');
    background-size: cover;
    width: 37px;
    height: 33px;
    position: absolute;
    top: 17px;
    left: 17px;
}

.total .other .shape {
    width: 352px;
    height: 401px;
    float: left;
    position: absolute;
    left: 372px;
    top: 448px;
}

.total .other .shape1 {
    width: 352px;
    height: 401px;
    float: left;
    position: absolute;
    top: 448px;
    left: 783px;
}

.total .other .shape2 {
    width: 352px;
    height: 401px;
    float: left;
    position: absolute;
    top: 448px;
    left: 1193px;
}

.total .downLogoPosition_service {
    position: absolute;
    height: 0px;
    width: 1920px;
    top: 54px;
}
</style>