import { createRouter, createWebHistory } from 'vue-router'
import IndexView from '../views/IndexView.vue'
import LoginView from '../views/LoginView.vue'
import RegisterView from '../views/RegisterView.vue'
import NoticeView from '../views/NoticeView.vue'
import NoticeWriteView from '../views/NoticeWriteView.vue'
import NoticeDetailsView from '../views/NoticeDetailsView.vue'
import NewsView from '../views/NewsView.vue'
import NewsViewWrite from '../views/NewsWriteView.vue'
import NewsViewDetails from '../views/NewsDetailsView.vue'
import ServiceView from '../views/ServiceView.vue'
import ForgotPasswordView from '../views/ForgotPasswordView.vue'
import SinglePageModule from '../views/SinglePageModule.vue'
import PersonalCenterViewVue from '@/views/PersonalCenterView.vue'
import ChangePasswordView from '@/views/ChangePasswordView.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexView
  },
  {
    path: '/toLogin',
    name: 'toLogin',
    component: LoginView,
    meta: {
      title: '登录'
    }
  },
  {
    path: '/toRegister',
    name: 'toRegister',
    component: RegisterView,
    meta: {
      title: '注册'
    }
  },
  {
    path: '/toNotice',
    name: 'toNotice',
    component: NoticeView,
    meta: {
      title: '速优公告'
    }
  },
  {
    path: '/toNoticeWrite',
    name: 'toNoticeWrite',
    component: NoticeWriteView,
    meta: {
      title: '速优公告'
    }
  },
  {
    path: '/toNoticeDetails',
    name: 'toNoticeDetails',
    component: NoticeDetailsView,
    meta: {
      title: '速优公告'
    }
  },
  {
    path: '/toNews',
    name: 'toNews',
    component: NewsView,
    meta: {
      title: '速优新闻'
    }
  },
  {
    path: '/toNewsWrite',
    name: 'toNewsWrite',
    component: NewsViewWrite,
    meta: {
      title: '速优新闻'
    }
  },
  {
    path: '/toNewsDetails',
    name: 'toNewsDetails',
    component: NewsViewDetails,
    meta: {
      title: '速优新闻'
    }
  },
  {
    path: '/toService',
    name: 'toService',
    component: ServiceView,
    meta: {
      title: '速优服务'
    }
  },
  {
    path: '/tochangePassword',
    name: 'tochangePassword',
    component: ForgotPasswordView,
    meta: {
      title: '忘记密码'
    }
  },
  {
    path: '/toSinglePage',
    name: 'toSinglePage',
    component: SinglePageModule,
    meta: {
      title: '速优规则'
    }
  },
  {
    path: '/personalCenterView',
    name: 'personalCenterView',
    component: PersonalCenterViewVue,
    beforeEnter: (to, from, next) => {//在页面跳转前，进行逻辑判断
      // token 存在 进入该路由，不存在跳转到登陆页面
      if (null != sessionStorage.getItem('SESSION_ID')) {
        next();
      } else {
        next('/toLogin');
      }
    },
    meta: {
      title: '个人中心'
    }
  },
  {
    path: '/updatePwdView',
    name: 'updatePwdView',
    component: ChangePasswordView,
    beforeEnter: (to, from, next) => {//在页面跳转前，进行逻辑判断
      // token 存在 进入该路由，不存在跳转到登陆页面
      if (null != sessionStorage.getItem('SESSION_ID')) {
        next();
      } else {
        next('/toLogin');
      }
    },
    meta: {
      title: '修改密码'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // 判断是否为复制的地址栏中的链接
  const isCopiedLink = !from.name && to.path !== '/'
  if (to.meta.title) {
    document.title = to.meta.title
  }
 
  if (isCopiedLink) {
    // 如果是复制的链接，则重定向到首页
    next({ path: '/' })
  } else {
    next()
  }
})

export default router
