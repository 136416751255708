<template>
    <div class="head">
        <div class="head_background_color">
            <router-link to="/">
                <div class="logo_background_img"></div>
            </router-link>
        </div>
        <div class="menu_position">
            <el-menu :default-active="$route.path" class="el-menu-demo navigation" mode="horizontal" router>
                <el-menu-item index="/">首页</el-menu-item>
                <el-menu-item index="/toNotice">公告</el-menu-item>
                <el-menu-item index="/toNews">新闻</el-menu-item>
                <el-menu-item index="/toService">服务</el-menu-item>
            </el-menu>
        </div>

        <div class="head_sculpture_position_img" v-if="null != session_id">
            <div class="head_sculpture"></div>
        </div>
        <div class="userName_position" v-if="null != session_id">
            <el-popover
                placement="top-start"
                trigger="hover"
                :width="150">
                <router-link to="/personalCenterView">
                    <div class="returnStyle" id="perFaId" @mouseenter="getFocus(1)" @mouseleave="lostFocus(1)">
                        <div class="returnClassType" style="left:45px;" id="perId">个人中心</div>
                    </div>
                </router-link>
                <a href="javaScript:void(0);" @click="confirmEvent()" @mouseenter="getFocus(2)" @mouseleave="lostFocus(2)" id="retFaId" class="returnStyle" style="top: 46px;">
                    <div class="returnClassType" id="retId">退出</div>
                </a> 
                <template #reference>
                    <a class="adminExit" href="javaScript:void(0);">{{ user_name_admin }}</a>
                </template>
            </el-popover>
        </div>

        <div class="login" v-if="null == session_id">
            <el-button class="loginButtonStyle" type="primary" plain @click="toLogin()">登录/注册</el-button>
        </div>
    </div>
</template>

<script>
import axios from "@/utils/request";

export default {
    data() {
        return {
            //0 未登录 1 已登陆
            loginStatus: '0',
            user_name_admin: '',
            session_id: ''
        }
    },
    created() {
        var that = this;
        that.user_name_admin = sessionStorage.getItem('USER_NAME')
        that.session_id = sessionStorage.getItem('SESSION_ID')
    },
    methods: {
        getFocus(e){
            if(e == 1){
                document.getElementById('perFaId').style.backgroundColor = '#0b60a5'
                document.getElementById('perId').style.color = '#ffffff'
            }
            if(e == 2){
                document.getElementById('retFaId').style.backgroundColor = '#0b60a5'
                document.getElementById('retId').style.color = '#ffffff'
            }
        },
        lostFocus(e){
            if(e == 1){
                document.getElementById('perFaId').style.backgroundColor = '#ffffff'
                document.getElementById('perId').style.color = '#545454'
            }
            if(e == 2){
                document.getElementById('retFaId').style.backgroundColor = '#ffffff'
                document.getElementById('retId').style.color = '#545454'
            }
        },
        toLogin() {
            this.$router.push({
                path: '/toLogin'
            });
        },
        confirmEvent() {
            var that = this
            var config = {
                method: 'post',
                url: '/a/logout',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                withCredentials: false
            };
            axios(config).then(function () {
                that.$confirm("退出成功", '消息', {
                    confirmButtonText: 'OK',
                    showCancelButton: false,
                    showClose: false,
                    closeOnClickModal: false,
                    closeOnHashChange: false,
                    closeOnPressEscape: false
                }).then(() => {
                    sessionStorage.removeItem('SESSION_ID')
                    sessionStorage.removeItem('USER_NAME')
                    sessionStorage.removeItem('user_id')
                    that.user_name_admin = '';
                    that.$router.push({
                        path: '/toLogin'
                    });
                });
            }).catch(function (error) {
                console.log(error);
            });
        }
    }
}
</script>

<style>

.returnStyle {
    width: 150px;
    height: 35px;
    position: absolute;
    left: -1px;
    top: 13px;
}

.returnClassType {
    font-size: 14px;
    color: rgb(84, 84, 84);
    line-height: 1.2;
    text-decoration: none;
    position: absolute;
    top: 9px;
    left: 60px;
}

#app .el-menu--horizontal>.el-menu-item.is-active {
    border-bottom: 4px solid #101010;
    color: #444444 !important;
}

.el-popover .el-popconfirm .el-button--small{
    background-color: transparent !important;
    color: #101010 !important;
    font-size: 14px;
}

.el-popconfirm__action .is-text {
    display: none;
}

.el-popconfirm__action .el-button--small {
    position: relative;
    right: 40px;
    top: -8px;
    font-family: "Alibaba";
    color: rgb(84, 84, 84);
    line-height: 1.2;
    color: black;
    background-color: white;
    border: none;
}

.el-popover {
    position: relative;
    top: 103.5px !important;
    left: 1419px !important;
    height: 90px !important;
}

.el-popover span {
    left: 0px !important;
}

/* 小三角形 */
span.el-popper__arrow::before {
    width: 20px;
    height: 20px;
    position: absolute !important;
    /* left: 45px !important; */
    /* left: 10px !important; */
    top: -5px !important;
    right: -70px !important;
}

.head {
    width: auto;
    height: 91px;
    border-radius: 0px 0px 0px 0px;
}

.head .head_background_color {
    width: 1920px;
    height: 91px;
}

.head .head_background_color .logo_background_img {
    background-image: url("../../assets/indexImg/logo.png");
    width: 288px;
    height: 71px;
    background-size: cover;
    /* height: 100%;
    width: 100%; */
    opacity: 1;
    position: absolute;
    left: 360px;
    top: 9px;
}

.head .menu_position {
    position: absolute;
    left: 768px;
    height: 91px;
    width: 600px;
    top: 0;
}

.head .menu_position .navigation {
    width: 600px;
    position: absolute;
}

.head .el-menu-demo .el-menu-item {
    position: relative;
    top: 1px;
    width: 129px;
    height: 90px;
    font-size: 14px;
    font-family: "Alibaba";
    color: rgb(68, 68, 68);
}

.head .navigation .thisMenu {
    top: 20px;
    font-size: 16px;
    font-family: "Alibaba";
    text-decoration: none;
}

.head .logoImg {
    position: absolute;
    width: 254px;
    height: 62.5px;
    left: 300px;
    top: 15px;
    z-index: 60;
}

.head .login {
    border-radius: 8px;
    position: absolute;
    left: 1410px;
    top: 22px;
    width: 149px;
    height: 51px;
}

.head .login .loginButtonStyle {
    width: 150px;
    height: 51px;
    background-color: rgb(11, 96, 166);
    border-radius: 7px;
    color: white;
    font-size: 14px;
    font-family: "Alibaba";

    position: absolute;
    left: 0px;
}

.head .head_sculpture_position_img {
    position: absolute;
    left: 1419px;
    top: 22px;
    width: 51px;
    height: 51px;
}

.head .head_sculpture_position_img .head_sculpture {
    background-image: url('../../assets/indexImg/head_sculpture.png');
    background-size: cover;
    width: 53px;
    height: 52px;
}

.head .userName_position {
    font-size: 14px;
    font-family: "Alibaba";
    color: rgb(84, 84, 84);
    line-height: 1.2;
    position: absolute;
    left: 1486.844px;
    top: 39.6px;
    z-index: 28;
    width: 160px;
    text-align: left;
}

.head .userName_position .adminExit {
    text-decoration: none;
    color: black;
}
</style>