<template>
    <div class="bottomTop"></div>
    <div class="bottom">
        <div class="bottomLogoPosition"></div>
        <div class="jumpBox">
            <router-link class="font01" to="/">首页</router-link>
            <router-link class="font02" to="/toNews">热点新闻</router-link>
            <router-link class="font03" to="/toNotice">公告</router-link>
            <router-link class="font04" to="/toService">服务条例</router-link>
        </div>

        <div class="verticalStick"></div>

        <div class="contactUs">
            <div class="font05">联系我们</div>
            <div class="font06">地&#12288;&#12288;址：天津市东丽区航空商务区B座513</div>
            <div class="font07">联系电话：400-2222-2234</div>
            <div class="font08">邮&#12288;&#12288;箱：suyoukuaidi@sina.com</div>
        </div>
        <div class="ROCode">
            <div class="RQCodeImg"></div>
        </div>
    </div>
</template>

<script>

</script>

<style scoped>
.bottomTop {
    width: auto;
    height: 20px;
    background: #242A7D;
    opacity: 1;

    position: relative;
    top: 714px;
}

.bottom {
    width: auto;
    height: 223px;
    background: #1B2649;
    opacity: 1;

    position: relative;
    top: 714px;
}

.bottom .bottomLogoPosition {
    background-image: url('../../assets/indexImg/logoDown.png');
    background-size: cover;
    position: absolute;
    left: 361px;
    width: 166px;
    height: 122px;
    top: 22px;
}

.bottom .jumpBox {
    position: relative;
    width: 158px;
    height: 50px;
    top: 85px;
    left: 617px;
}

.bottom .font01 {
    font-size: 14px;
    font-family: "Alibaba";
    color: rgb(255, 255, 255);
    line-height: 1.2;
    position: relative;
    text-decoration: none;
}

.total .bottom .font01 {
    font-size: 14px;
    font-family: "Alibaba";
    color: rgb(255, 255, 255);
    line-height: 1.2;
    position: absolute;
    left: 0px;
    text-decoration: none;
}

.bottom .font02 {
    font-size: 14px;
    font-family: "Alibaba";
    color: rgb(255, 255, 255);
    line-height: 1.2;
    position: absolute;
    right: 0px;
    text-decoration: none;
}

.bottom .font03 {
    font-size: 14px;
    font-family: "Alibaba";
    color: rgb(255, 255, 255);
    line-height: 1.2;
    position: absolute;
    bottom: 0px;
    left: 0px;
    text-decoration: none;
}

.bottom .font04 {
    font-size: 14px;
    font-family: "Alibaba";
    color: rgb(255, 255, 255);
    line-height: 1.2;
    position: absolute;
    bottom: 0px;
    right: 0px;
    text-decoration: none;
}

.bottom .verticalStick {
    background-color: rgb(255, 255, 255);
    position: absolute;
    width: 1px;
    height: 92px;
    z-index: 118;
    left: 1067px;
    top: 59px;
}

.bottom .contactUs {
    position: absolute;
    top: 59px;
    width: 290px;
    height: 90px;
    left: 1124px;
}

.bottom .font05 {
    font-family: "Alibaba";
    color: rgb(255, 255, 255);
    position: absolute;
    top: -3px;
    font-size: 18px;
    line-height: 1.333;
}

.bottom .font06 {
    font-family: "Alibaba";
    color: rgb(255, 255, 255);
    position: absolute;
    top: 27px;
    font-size: 14px;
    line-height: 1.333;
    text-align: left;
}

.bottom .font07 {
    font-family: "Alibaba";
    color: rgb(255, 255, 255);
    position: absolute;
    top: 50px;
    font-size: 14px;
    line-height: 1.333;
    text-align: left;
}

.bottom .font08 {
    font-family: "Alibaba";
    color: rgb(255, 255, 255);
    position: absolute;
    top: 73px;
    font-size: 14px;
    line-height: 1.333;
    text-align: left;
}

.bottom .ROCode {
    width: 91px;
    height: 91px;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    background-color: #FFFFFF;
    position: absolute;
    top: 60px;
    left: 1469px;
}

.bottom .RQCodeImg {
    background-image: url('../../assets/indexImg/QRCode.png');
    background-size: cover;
    position: absolute;
    width: 81px;
    height: 81px;
    top: 5px;
    left: 5px;
}
</style>