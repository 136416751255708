<template>
    <PersonalCenterHeadView />
    <router-link to="/personalCenterView" style="text-decoration:none;">
        <div class="list_button_position_identity" style="background-color: #fafafa;">
            <div class="list_button_position_identity_img_modity"></div>
            <div class="list_button_position_identity_characters" style="color: rgb(83, 83, 73);">身份验证</div>
        </div>
    </router-link>
    <router-link to="/updatePwdView" style="text-decoration:none;">
        <div class="list_button_position_updatepassword">
            <div class="list_button_position_update_img_modify"></div>
            <div class="list_button_position_identity_characters">修改密码</div>
        </div> 
    </router-link>
    <div class="update_body">
        <div class="update_old_pwd_pos">
            <div class="update_old_pwd_characters_size">
                <div class="update_old_pwd_characters_star">*</div>
                <div class="update_old_pwd_characters">原密码</div>
            </div>
            <el-input v-bind:class="{update_old_pwd: old_pwd_class, isfail: oldfail}" @blur="handleChange(1)" @focus="fouceChange(1)" autocomplete="new-password" type="password" v-model="oldPwd" placeholder="请输入原有密码"/>
        </div>

        <div class="update_new_pwd_pos">
            <div class="update_old_pwd_characters_size">
                <div class="update_old_pwd_characters_star">*</div>
                <div class="update_old_pwd_characters">新密码</div>
            </div>
            <el-input v-bind:class="{update_old_pwd: old_pwd_class, isfail: newfail}" @blur="handleChange(2)" @focus="fouceChange(2)" autocomplete="new-password" type="password" v-model="newPwd" placeholder="请输入新密码" />
        </div>

        <div class="update_agin_pwd_pos">
            <div class="update_old_pwd_characters_size">
                <div class="update_agin_pwd_characters_star">*</div>
                <div class="update_old_pwd_characters">再次输入新密码</div>
            </div>
            <el-input v-bind:class="{update_old_pwd: old_pwd_class, isfail: aginfail}" @blur="handleChange(3)" @focus="fouceChange(3)" autocomplete="new-password" type="password" v-model="aginPwd" placeholder="请再次输入新密码" />
        </div>
        <div class="update_hengxian"></div>
        <a href="javaScript:void(0);">
            <div class="but_pos" @click="update_pwd()">
                <div class="but_pos_characters">提交</div>
            </div>
        </a>
        <router-link to="/tochangePassword" class="forgotPassword">忘记密码 ?</router-link>
    </div>
    <div class="pre_body_characters">修改密码</div>
</template>

<script>
import PersonalCenterHeadView from '@/components/comm/PersonalCenterHeadView.vue'
import common from '@/utils/common'
import axios from "@/utils/request"
var qs = require('qs');

export default {
    data() {
        return {
            old_pwd_class: true,
            oldfail: false,
            newfail: false,
            aginfail: false,
            oldPwd: '',
            newPwd: '',
            aginPwd: ''
        }
    },
    methods:{
        handleChange(e){
            var that = this
            if(e == 1 && that.oldPwd == ''){
                that.oldfail = true
            }
            if(e == 2 && that.newPwd == ''){
                that.newfail = true
            }
            if(e == 3 && that.aginPwd == ''){
                that.aginfail = true
            }
        },
        fouceChange(e){
            var that = this
            if(e == 1 && that.oldPwd == ''){
                that.oldfail = false
            }
            if(e == 2 && that.newPwd == ''){
                that.newfail = false
            }
            if(e == 3 && that.aginPwd == ''){
                that.aginfail = false
            } 
        },
        update_pwd(){
            var session_id = sessionStorage.getItem('SESSION_ID');
            var user_id = sessionStorage.getItem('user_id');
            var that = this;
            if(session_id == null || user_id == null){
                that.$confirm('请先登录', '消息', {
                        confirmButtonText: 'OK',
                        showCancelButton: false,
                        showClose: false,
                        closeOnClickModal: false,
                        closeOnHashChange: false,
                        closeOnPressEscape: false
                    }).then(() => {
                        if (that.checked1) {
                            sessionStorage.removeItem('SESSION_ID')
                            sessionStorage.removeItem('USER_NAME')
                            sessionStorage.removeItem('user_id')
                        }
                        common.rout('/toLogin')
                    })
            }
            if(that.oldPwd == ''){
                common.msg("原密码为空，请重新输入");
                that.oldfail = true
                return false;
            }
            if(that.newPwd == ''){
                common.msg("新密码为空，请重新输入");
                that.newfail = true
                return false;
            }
            if(that.aginPwd == ''){
                common.msg("二次输入新密码为空，请重新输入");
                that.aginfail = true
                return false;
            }
            if(that.newPwd != that.aginPwd){
                common.msg("两次输入的新密码不相同，请重新输入");
                that.newfail = true
                that.aginfail = true
                return false;
            }
            var data = {};
            var data = qs.stringify({
                'id': user_id,
                'oldPassword': that.oldPwd,
                'newPassword': that.newPwd,
                'confirmNewPassword': that.aginPwd
            });
            var config = {
                method: 'post',
                url: '/a/sys/user/modifyPwd;JSESSIONID='+common.encryption(session_id)+'?__ajax=true',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                data: data,
                withCredentials: false
            };
            axios(config).then(function (response) {
                if(response.indexOf("旧密码错误") != -1){
                    common.msg("修改密码失败，原密码错误")
                }
                if(response.indexOf("修改密码成功") != -1){
                    localStorage.setItem('login_pwd',common.encryption(that.aginPwd))
                    common.msg("修改密码成功")
                    that.oldPwd = ""
                    that.newPwd = ""
                    that.aginPwd = ""
                }
            }).catch(function (error) {
                console.log(error);
            });
        }
    },
    components: {
        PersonalCenterHeadView
    }
}
</script>

<style>

.update_body {
    border-width: 2px;
    border-color: #e9e9e9;
    border-style: solid;
    background-color: rgb(255, 255, 255);
    position: absolute;
    width: 901px;
    height: 760px;
    top: 0px;
    left: 627px;
    top: 278px;
}

.list_button_position_identity {
    background-color: rgb(11, 96, 165);
    position: absolute;
    width: 143px;
    height: 36px;
    z-index: 18;
    border-radius: 50px;
    left: 397.5px;
    top: 228px;
}

.list_button_position_updatepassword {
    background-color: rgb(11, 96, 165);
    position: absolute;
    width: 143px;
    height: 36px;
    z-index: 18;
    border-radius: 50px;
    left: 397.5px;
    top: 288px;
}

.list_button_position_identity_img_modity {
    width: 17px;
    height: 14px;
    position: relative;
    background-image: url("../assets/personImg/id_card_img_black.png");
    left: 20px;
    top: 10.5px;
    z-index: 60;
}

.list_button_position_update_img_modify {
    width: 15px;
    height: 15px;
    position: relative;
    background-image: url("../assets/personImg/update_pwd_write.png");
    left: 20px;
    top: 10.5px;
}

.list_button_position_identity_characters {
    position: relative;
    color: white;
    font-size: 14px;
    text-decoration: none;
    height: 30px;
    width: 68px;
    left: 42px;
    top: -7px;
}

.update_body .update_old_pwd_pos {
    height: 38px;
    width: 100%;
    position: absolute;
    top: 35px;
}

.update_body .update_new_pwd_pos {
    height: 38px;
    width: 100%;
    position: absolute;
    top: 110px;
}

.update_body .update_agin_pwd_pos {
    height: 38px;
    width: 100%;
    position: absolute;
    top: 185px;
}

.update_old_pwd_characters_size {
    width: 195px;
    height: 20px;
    float: left;
    position: absolute;
    top: 12px;
    text-align: right;
}

.update_old_pwd_characters_star {
    color: red;
    float: left;
    position: absolute;
    right: 42px;
}

.update_old_pwd_characters {
    font-size: 14px;
    line-height: 1.2;
}

.update_body .update_old_pwd {
    position: absolute;
    width: 484px;
    height: 38px;
    left: 183px;
}

.update_body .update_agin_pwd_characters_star {
    color: red;
    float: left;
    position: absolute;
    right: 98px;
}

.update_body .update_hengxian {
    background-color: rgb(208, 208, 208);
    position: absolute;
    width: 493px;
    height: 1px;
    top: 265px;
    left: 208px;
}

.update_body .but_pos {
    border-radius: 6px;
    background-color: rgb(11, 96, 165);
    width: 115px;
    height: 38px;
    position: absolute;
    left: 411px;
    top: 300px;
}

.update_body .but_pos .but_pos_characters {
    font-size: 14px;
    color: rgb(255, 255, 255);
    line-height: 1.2;
    position: relative;
    top: 9.5px;
}

.forgotPassword {
    color: black;
    text-decoration: none;
    left: 535px;
    top: 307px;
    position: absolute;
}

.pre_body_characters {
    font-size: 16px;
    color: rgb(83, 83, 73);
    line-height: 1.2;
    position: absolute;
    font-weight: 600;
    left: 642px;
    top: 250px;
}

</style>