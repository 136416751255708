<template>
    <HeadView />
    <div class="total">
        <div class="head_down01">
            <div class="notiveImg"></div>
        </div>
        <div class="order">
            <div class="unknowImg"></div>
            <div class="wenziPosition">
                <div class="wenziStyle">暂时没有内容</div>
                <router-link to="/" class="returnIndex">返回首页</router-link> 
            </div>
        </div>
        <div class="downLogoPosition">
            <TailView />
        </div>
    </div>
</template>

<script>
import HeadView from '@/components/comm/HeadView';
import TailView from '@/components/comm/TailView';
export default{
    components: {
        HeadView,
        TailView
    }
}
</script>

<style scoped>

.total {
    width: 1920px;
    height: 1391px;
}

.total .head_down01 {
    top: 91px;
    width: auto;
    height: 288px;
    opacity: 1;
}

.total .head_down01 .notiveImg {
    background-image: url('../assets/noticeImg/noticeBackground.png');
    background-size: contain;
    height: 288px;
    width: 1920px;
}

.total .order {
    position: absolute;
}

.total .order .wenziPosition {
    position: relative;
    height: 100px;
    width: 100px;
    position: relative;
    top: 100px;
}

.total .order .wenziPosition .wenziStyle {
    font-size: 16px;
    color: rgb(0, 0, 0);
    line-height: 1.2;
    text-align: center;
    position: absolute;
    top: 442px;
    left: 915px;
    width: 100px;
}

.total .order .unknowImg {
    background-image: url('../assets/noticeImg/unKnowImg.png');
    background-size: contain;
    width: 473px;
    height: 332px;
    position: absolute;
    top: 182px;
    left: 724.7px;
}

.total .order .returnIndex{
    font-size: 15px;
    z-index: 16;
    line-height: 1.2;
    
    text-decoration: none;
    color: #3a5fe5;

    position: absolute;
    width: 60px;
    top: 462px;
    left: 933px;
}

.total .downLogoPosition{
    position: absolute;
    width: 1920px;
    top: 369px;
}

</style>