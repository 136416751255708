<template>
    <PersonalCenterHeadView />
    <router-link to="/personalCenterView" style="text-decoration:none;">
        <div class="list_button_position_identity">
            <div class="list_button_position_identity_img_modity_per"></div>
            <div class="list_button_position_identity_characters">身份验证</div>
        </div>
    </router-link>
    <router-link to="/updatePwdView" style="text-decoration:none;">
        <div class="list_button_position_updatepassword" style="background-color: #fafafa;">
            <div class="list_button_position_update_img_modify_per"></div>
            <div class="list_button_position_identity_characters" style="color: rgb(83, 83, 73);">修改密码</div>
        </div> 
    </router-link>
    <div class="update_body">
        <div class="update_old_pwd_pos">
            <div class="update_old_pwd_characters_size">
                <div class="identity_old_pwd_characters_star">*</div>
                <div class="update_old_pwd_characters">姓名</div>
            </div>
            <el-input v-bind:class="{update_old_pwd: old_pwd_class, isfail: isfail}" autocomplete="new-password" v-model="uName" placeholder="请输入您的真实姓名" v-if="'4' != idCardStatus"/>
            <div class="update_old_pwd_characters_uname" v-if="4 == idCardStatus">{{uName}}</div>
        </div>

        <div class="update_new_pwd_pos">
            <div class="update_old_pwd_characters_size">
                <div class="identity_old_pwd_characters_star01">*</div>
                <div class="update_old_pwd_characters">身份证</div>
            </div>
            <el-input v-bind:class="{update_old_pwd: old_pwd_class, isfail: isfail}" autocomplete="new-password" v-model="IdCardNum" placeholder="请输入您的身份证号" v-if="'4' != idCardStatus"/>
            <div class="update_old_pwd_characters_uname" style="top:12px;" v-if="4 == idCardStatus">{{IdCardNum}}</div>
        </div>

        <div class="errorFeedback" v-if="'9' == idCardStatus">{{idCardRemarks}}</div>

        <div class="update_agin_pwd_pos">
            <div class="update_old_pwd_characters_size">
                <div class="identity_old_pwd_characters_star02">*</div>
                <div class="update_old_pwd_characters">上传证件</div>

                <div class="id_care_front_pos">
                    <el-upload
                        :limit="1"
                        :on-change="frontHandleChange"
                        :auto-upload="false"
                        :file-list="fileList"
                        :show-file-list="false"
                        accept='.jpg, .png, .pdf'
                        v-if="'0' == uploadFrontFaleStatus">
                        <div class="id_care_front_pos_bg">
                            <div class="id_card_pos_add"></div>
                            <div class="id_card_pos_add_characters">上传正面</div>
                        </div>
                    </el-upload>

                    <div class="id_care_front_pos_bg_upload"  v-if="'1' == uploadFrontFaleStatus">
                        <img :src="[[idCardFont]]" style="width: 237px;height: 150px;">
                    </div>

                    <div class="id_card_audit_status" v-if="'1' == idCardStatus || '2' == idCardStatus"></div>
                    <div class="id_card_examine_status" v-if="'3' == idCardStatus"></div>
                    <a href="javaScript:void(0);" @click="Re_upload(1)">
                        <div class="id_card_fail_status" v-if="'9' == idCardStatus && failFrontStatus == '0'"></div>
                    </a>
                    <div class="id_card_authentication_status" v-if="'4' == idCardStatus"></div>

                    <div class="id_care_front_pos_characters">身份证正面（人像面）</div>
                </div>

                <div class="id_care_black_pos">
                    <el-upload
                        :limit="1"
                        :on-change="BlackHandleChange"
                        :auto-upload="false"
                        :file-list="fileList"
                        :show-file-list="false"
                        accept='.jpg, .png, .pdf'
                        v-if="'0' == uploadBlackFaleStatus">
                        <div class="id_care_black_pos_bg">
                            <div class="id_card_pos_add"></div>
                            <div class="id_card_pos_add_characters">上传反面</div>
                        </div>
                    </el-upload>

                    <div class="id_care_front_pos_bg_upload"  v-if="'1' == uploadBlackFaleStatus">
                        <img :src="[[idCardBlack]]" style="width: 237px;height: 150px;">
                    </div>

                    <div class="id_card_audit_status" v-if="'1' == idCardStatus || '2' == idCardStatus"></div>
                    <div class="id_card_examine_status" v-if="'3' == idCardStatus"></div>
                    <a href="javaScript:void(0);" @click="Re_upload(2)">
                        <div class="id_card_fail_status" v-if="'9' == idCardStatus && failBlackStatus == '0'"></div>
                    </a>
                    <div class="id_card_authentication_status" v-if="'4' == idCardStatus"></div>

                    <div class="id_care_front_pos_characters">身份证反面（国徽面）</div>
                </div>
            </div>
        </div>

        <div class="upload_format_postion">支持上传JPG、PNG、PDF格式，大小不超过1M</div>
        <div class="identity_hengxian"></div>
        <a href="javaScript:void(0);" v-if="'0' == idCardStatus || '9' == idCardStatus">
            <div class="identity_pos" @click="submit_pwd()">
                <div class="but_pos_characters">提交</div>
            </div>
        </a>
        <div class="identity_pos" style="background-color:#e6e6e6;" v-if="idCardStatus == '1' || idCardStatus == '2'">
            <div class="but_pos_characters">提交</div>
        </div>
        <a href="javaScript:void(0);" v-if="'3' == idCardStatus">
            <div class="identity_pos" @click="submit_update()">
                <div class="but_pos_characters">提交</div>
            </div>
        </a>
    </div>
    <div class="pre_body_characters">账号认证</div>
</template>

<script>
import PersonalCenterHeadView from '@/components/comm/PersonalCenterHeadView.vue'
import md5 from "js-md5";
import axios from "@/utils/request"
import common from '@/utils/common';

export default {
    data() {
        return {
            old_pwd_class: true,
            isfail: false,
            failFrontStatus: '0',
            failBlackStatus: '0',
            uName: '',
            IdCardNum: '',
            idCardFont: '',
            idCardBlack: '',
            idCardStatus: '0',
            uploadFrontFaleStatus: '0',
            uploadBlackFaleStatus: '0',
            fileList: [],
            idCardRemarks: ''
        }
    },
    created(){
        var that = this;
        that.getIdentityAuthAuthentication();
    },
    methods:{
        Re_upload(e){
            var that = this
            if(e == 1){
                that.idCardFont = ''
                that.uploadFrontFaleStatus = '0'
                that.failFrontStatus = '1'
            }
            if(e == 2){
                that.idCardBlack = ''
                that.uploadBlackFaleStatus = '0'
                that.failBlackStatus = '1'
            }
        },
        submit_update(){
            var SESSION_ID = sessionStorage.getItem('SESSION_ID');
            if(SESSION_ID != null){
                var user_id = sessionStorage.getItem('user_id');
                var that = this;
                var formData = new FormData()
                formData.append('authenticationid', common.decrypt(user_id))
                var config = {
                    method: 'post',
                    url: '/api/front/submitCertificationSuccessful;JSESSIONID='+common.encryption(sessionStorage.getItem('SESSION_ID'))+'?__ajax=true',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    },
                    data: formData,
                    withCredentials: false
                };
                axios(config).then(function (response) {
                    if(response.code == '000001'){
                        window.location.reload();
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            }
        },
        getIdentityAuthAuthentication(){
            var SESSION_ID = sessionStorage.getItem('SESSION_ID');
            if(SESSION_ID != null){
                var user_id = common.decrypt(sessionStorage.getItem('user_id'));
                var that = this;
                var formData = new FormData()
                formData.append('id', user_id)
                var config = {
                    method: 'post',
                    url: '/api/front/getIdentityAuthAuthentication;JSESSIONID='+common.encryption(SESSION_ID)+'?__ajax=true',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    },
                    data: formData,
                    withCredentials: false
                };
                axios(config).then(function (response) {
                    if(response.code == '000001'){
                        var entity = response.data;
                        that.uploadBlackFaleStatus = '1'
                        that.uploadFrontFaleStatus = '1'
                        that.uName = entity.authenticationname
                        that.IdCardNum = entity.authenticationidcard
                        that.idCardFont = entity.authenticationidcardfrontimg
                        that.idCardBlack = entity.authenticationidcardblackimg
                        that.idCardStatus = entity.authenticationstatus
                        that.idCardRemarks = entity.authenticationRemarks
                        if(entity.authenticationstatus == '9' && entity.authenticationRemarks.indexOf('身份校验失败') != -1){
                            that.isfail = true
                        }
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            }
        },
        BlackHandleChange(file, fileLists){
            this.uploadBlackFaleStatus = '1'
            var formData = new FormData()
            var filename = md5(document.getElementsByClassName("el-upload__input")[0].value) + '.png'
            formData.append('status', 'black')
            formData.append('fileName', filename)
            formData.append('userId', sessionStorage.getItem('user_id'))
            formData.append('image', file.raw)
            this.handleChange(formData)
        },
        frontHandleChange(file, fileLists) {
            this.uploadFrontFaleStatus = '1'
            var filename = md5(document.getElementsByClassName("el-upload__input")[0].value) + '.png'
            var formData = new FormData()
            formData.append('status', 'front')
            formData.append('fileName', filename)
            formData.append('userId', sessionStorage.getItem('user_id'))
            formData.append('image', file.raw)
            this.handleChange(formData)
		},
        handleChange(formData){
            var that = this;
            var config = {
                method: 'post',
                url: '/api/front/uploadAuthenticationImg;JSESSIONID='+common.encryption(sessionStorage.getItem('SESSION_ID'))+'?__ajax=true',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                data: formData,
                withCredentials: false
            };
            axios(config).then(function (response) {
                if(response.code == "000001"){
                    var map = response.data
                    if(map.status == "front"){
                        that.idCardFont = common.GlobalUrl + map.url
                    }
                    if(map.status == "black"){
                        that.idCardBlack = common.GlobalUrl + map.url
                    }
                }else{
                    common.msg(response.message)
                }
            }).catch(function (error) {
                console.log(error);
            });
        },
        submit_pwd() {
            var that = this;
            if(that.uName == ''){
                common.msg('真实姓名为空');
                return false
            }
            if(that.IdCardNum == ''){
                common.msg('身份证号为空');
                return false
            }
            if(that.idCardFont == ''){
                common.msg('身份证正面照片为空');
                return false
            }
            if(that.idCardBlack == ''){
                common.msg('身份证背面照片为空');
                return false
            }
            var formData = {
                "uName": that.uName,
                "IdCardNum": that.IdCardNum,
                "idCardFont": that.idCardFont,
                "idCardBlack": that.idCardBlack
            }
            var config = {
                method: 'post',
                url: '/api/front/authentication;JSESSIONID='+common.encryption(sessionStorage.getItem('SESSION_ID'))+'?__ajax=true',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                data: formData,
                withCredentials: false
            };
            axios(config).then(function (response) {
                that.$confirm(response.message, '消息', {
                    confirmButtonText: 'OK',
                    showCancelButton: false,
                    showClose: false,
                    closeOnClickModal: false,
                    closeOnHashChange: false,
                    closeOnPressEscape: false
                }).then(() => {
                    window.location.reload();
                })
            }).catch(function (error) {
                console.log(error);
            });
        },
        getNowFormatDate() {
            return new Date().getTime();
        }
    },
    components: {
        PersonalCenterHeadView
    }
}
</script>

<style>

.isfail .el-input__wrapper {
    box-shadow: 0 0 0 1px red inset !important;
}

.update_body {
    border-width: 2px;
    border-color: #e9e9e9;
    border-style: solid;
    background-color: rgb(255, 255, 255);
    position: absolute;
    width: 901px;
    height: 760px;
    top: 0px;
    left: 627px;
    top: 278px;
}

.list_button_position_identity_img_modity_per {
    width: 17px;
    height: 14px;
    position: relative;
    background-image: url("../assets/personImg/id_card_img_write.png");
    left: 20px;
    top: 10.5px;
}

.list_button_position_update_img_modify_per {
    width: 15px;
    height: 15px;
    position: relative;
    background-image: url("../assets/personImg/update_pwd_black.png");
    left: 20px;
    top: 10.5px;
}

.update_body .errorFeedback {
    font-size: 14px;
    color: red;
    position: absolute;
    left: 230px;
    top: 160px;
}

.update_body .update_old_pwd_pos {
    height: 38px;
    width: 100%;
    position: absolute;
    top: 35px;
}

.update_body .update_new_pwd_pos {
    height: 38px;
    width: 100%;
    position: absolute;
    top: 110px;
}

.update_old_pwd_characters_size {
    width: 195px;
    height: 20px;
    float: left;
    position: absolute;
    top: 12px;
    text-align: right;
}

.update_old_pwd_characters_size .id_care_front_pos {
    position: absolute;
    width: 237px;
    height: 178px;
    left: 211px;
    top: 0px;
}

.update_old_pwd_characters_size .id_care_black_pos {
    position: absolute;
    width: 237px;
    height: 178px;
    left: 459px;
    top: 0px;
}

.update_old_pwd_characters_size .id_care_front_pos_bg {
    background-image: url('@/assets/personImg/img_front_idcard_bg.png');
    position: absolute;
    left: 0px;
    top: 0px;
    width: 237px;
    height: 150px;
}

.update_old_pwd_characters_size .id_care_front_pos_bg_upload {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 237px;
    height: 150px;
}

.update_old_pwd_characters_size .id_card_audit_status {
    position: absolute;
    background-image: url('@/assets/personImg/shenhezhong.png');
    left: 0px;
    top: 0px;
    width: 237px;
    height: 150px;
    z-index: 40;
}

.update_old_pwd_characters_size .id_card_examine_status {
    position: absolute;
    background-image: url('@/assets/personImg/shenhetongguo.png');
    left: 0px;
    top: 0px;
    width: 237px;
    height: 150px;
    z-index: 40;
}

.update_old_pwd_characters_size .id_card_fail_status {
    position: absolute;
    background-image: url('@/assets/personImg/shenheweitongguo.png');
    left: 0px;
    top: 0px;
    width: 237px;
    height: 150px;
    z-index: 40;
}

.update_old_pwd_characters_size .id_card_authentication_status {
    position: absolute;
    background-image: url('@/assets/personImg/renzhengchenggong.png');
    left: 0px;
    top: 0px;
    width: 237px;
    height: 150px;
    z-index: 40;
}

.update_old_pwd_characters_size .id_care_black_pos_bg {
    background-image: url('@/assets/personImg/img_black_idcard_bg.png');
    position: absolute;
    width: 237px;
    height: 150px;
    top: 0px;
    left: 0px;
}

.update_old_pwd_characters_size .id_care_front_pos_bg .id_card_pos_add {
    width: 39px;
    height: 39px;
    background-image: url('@/assets/personImg/upload_add_img.png');
    position: relative;
    left: 100px;
    top: 50px;
}

.update_old_pwd_characters_size .id_care_front_pos_bg .id_card_pos_add_characters {
    font-size: 14px;
    color: #397ab6;
    line-height: 1.2;
    position: absolute;
    left: 92px;
    top: 98px;
}

.update_old_pwd_characters_size .id_care_black_pos_bg .id_card_pos_add {
    width: 39px;
    height: 39px;
    background-image: url('@/assets/personImg/upload_add_img.png');
    position: relative;
    left: 100px;
    top: 50px;
}

.update_old_pwd_characters_size .id_care_black_pos_bg .id_card_pos_add_characters {
    font-size: 14px;
    color: #397ab6;
    line-height: 1.2;
    position: absolute;
    left: 92px;
    top: 98px;
}

.update_old_pwd_characters_size .id_care_front_pos_characters{
    font-size: 14px;
    color: rgb(83, 83, 73);
    line-height: 1.2;
    position: absolute;
    left: 53px;
    bottom: 0px;
}

.update_old_pwd_characters_star {
    color: red;
    float: left;
    position: absolute;
    right: 42px;
}

.update_old_pwd_characters {
    font-size: 14px;
    line-height: 1.2;
}

.update_body .update_old_pwd {
    position: absolute;
    width: 484px;
    height: 38px;
    left: 183px;
}

.update_body .update_old_pwd_characters_uname {
    position: absolute;
    left: 213px;
    top: 10px;
    width: 400px;
    text-align: left;
    font-size: 14px;
    color: rgb(83, 83, 73);
}

.update_body .update_agin_pwd_pos {
    height: 38px;
    width: 100%;
    position: absolute;
    top: 185px;
}

.update_body .identity_old_pwd_characters_star {
    color: red;
    float: left;
    position: absolute;
    right: 28px;
}

.update_body .identity_old_pwd_characters_star01 {
    color: red;
    float: left;
    position: absolute;
    right: 41px;
}

.update_body .identity_old_pwd_characters_star02 {
    color: red;
    float: left;
    position: absolute;
    right: 53px;
}

.update_body .upload_format_postion {
    font-size: 14px;
    color: rgb(185, 185, 185);
    line-height: 1.2;
    position: absolute;
    left: 216px;
    top: 400px;
    z-index: 38;
}

.update_body .identity_hengxian {
    background-color: rgb(208, 208, 208);
    position: absolute;
    width: 493px;
    height: 1px;
    top: 427px;
    left: 208px;
}

.update_body .identity_pos {
    border-radius: 6px;
    background-color: rgb(11, 96, 165);
    width: 115px;
    height: 38px;
    position: absolute;
    left: 411px;
    top: 468px;
}

.update_body .identity_pos .but_pos_characters {
    font-size: 14px;
    color: rgb(255, 255, 255);
    line-height: 1.2;
    position: relative;
    top: 9.5px;
}

.pre_body_characters {
    font-size: 16px;
    color: rgb(83, 83, 73);
    line-height: 1.2;
    position: absolute;
    font-weight: 600;
    left: 642px;
    top: 250px;
}

</style>