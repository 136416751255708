<template>
    <HeadView />
    <div class="total">
        <div class="head_down01">
            <div class="notiveImg"></div>
        </div>
        <div class="order">
            <div class="latestPosition">
                <div class="bigPogitiona">
                    <div class="iconBigSmall"></div>
                    <div class="wenziLinkPositon">
                        <router-link class="wenziLinkStyle" to="/">首页</router-link> >
                        <router-link class="wenziLinkStyle" to="/toNotice">公告</router-link> >
                    </div>
                    <div class="newNotice">最新公告</div>
                </div>
            </div>
            <div class="announcementSubject">
                <div class="announcementTitle">
                    {{ list.title }}
                </div>
                <div class="authorName">{{ list.author }}</div>
                <div class="round"></div>
                <div class="releaseTime">{{ list.time }}</div>
                <div class="lookNumber">浏览{{ list.number }}次</div>
                <div class="contentSubject" v-html="[[list.contentup]]"></div>
            </div>
        </div>
        <div class="changhengxian"></div>
        <div class="downLogoPosition">
            <TailView />
        </div>
    </div>
</template>

<script>
import HeadView from '@/components/comm/HeadView';
import TailView from '@/components/comm/TailView';
import axios from "@/utils/request";
import common from '@/utils/common';

export default {
    data() {
        return {
            id: this.$route.query.id,
            list: {}
        }
    },
    created() {
        var that = this;
        that.getNoticeMessageById(this.id);
    },
    methods: {
        getNoticeMessageById(id) {
            axios({
                methods: 'post',
                url: '/api/noticeAndNews/getNoticeMessageById?id=' + id,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                if (response.code == "999999") {
                    common.rout('/toNoticeWrite')
                    return false;
                }
                this.upHots(id)
                var newsList = this.list;
                newsList.title = response.data.article.title
                newsList.author = response.data.author
                newsList.time = response.data.article.createDate.slice(0, 16)
                newsList.number = response.data.article.hits
                newsList.contentup = response.data.article.title
                // console.log(this.list)
            }).catch(() => {
                common.rout('/toNoticeWrite')
                return false;
            });
        },
        upHots(id) {
            axios({
                methods: 'post',
                url: '/api/noticeAndNews/upHots?id=' + id,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
        }
    },
    components: {
        HeadView,
        TailView
    }
}
</script>

<style scoped>
.total {
    width: 1920px;
    height: 1263px;
}

.total .head_down01 {
    top: 91px;
    width: auto;
    height: 288px;
    opacity: 1;
}

.total .head_down01 .notiveImg {
    background-image: url('../assets/noticeImg/noticeBackground.png');
    background-size: contain;
    height: 288px;
    width: 1920px;
}

.total .order {
    width: auto;
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.total .order .latestPosition {
    width: 1201px;
    height: 63px;
    background: #EEECEC;
    opacity: 1;

    position: absolute;
    left: 360px;
    top: 16px;
}

.total .order .latestPosition .bigPogitiona {
    font-size: 24px;
    color: rgb(27, 38, 73);
    line-height: 1.2;
    font-weight: 600;

    position: absolute;
    top: 14px;
    left: 12px;
}

.total .order .latestPosition .bigPogitiona .wenziLinkPositon {
    position: absolute;
    width: 150px;
    top: 9px;
    left: 10px;

    font-size: 14px;
    color: rgb(133, 133, 133);
    line-height: 1.2;
}

.total .order .latestPosition .bigPogitiona .wenziLinkPositon .wenziLinkStyle {
    text-decoration: none;
    color: #9a9a9a;
}

.total .order .latestPosition .bigPogitiona .newNotice {
    width: 100px;
    position: absolute;
    top: 9.49px;
    left: 115px;

    font-size: 14px;
    color: rgb(27, 38, 73);
    line-height: 1.2;
    font-weight: 600;
}

.total .order .latestPosition .bigPogitiona .iconBigSmall {
    background-image: url('../assets/noticeImg/house.png');
    background-size: contain;
    width: 19px;
    height: 19px;

    position: absolute;
    top: 7px;
    left: 11px;
}

.total .order .announcementSubject {
    width: 1060px;
    height: 1060px;
    position: absolute;
    top: 139px;
    left: 441px;
    z-index: 600;
}

.total .order .announcementSubject .announcementTitle {
    font-size: 24px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    font-weight: 600;

    position: absolute;
    top: 0px;
    left: 413.5px;
}

.total .order .announcementSubject .authorName {
    font-size: 14px;
    color: rgb(195, 195, 195);
    line-height: 1.2;

    position: absolute;
    top: 52px;
    left: 389px;
}

.total .order .announcementSubject .round {
    background-image: url('../assets/noticeImg/person.png');
    background-size: contain;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #858585;

    position: absolute;
    top: 46px;
    left: 354px;
}

.total .order .announcementSubject .releaseTime {
    font-size: 14px;
    color: rgb(195, 195, 195);
    line-height: 1.2;

    position: absolute;
    top: 52px;
    left: 466px;
}

.total .order .announcementSubject .lookNumber {
    font-size: 14px;
    color: rgb(195, 195, 195);
    line-height: 1.2;

    position: absolute;
    top: 51px;
    left: 600px;
}

.total .order .announcementSubject .contentSubject {
    font-size: 16px;
    color: rgb(111, 111, 111);
    line-height: 1.2;
    text-align: left;

    position: absolute;
    top: 127px;
    text-indent: 32px;
}

.total .order .announcementSubject .picturePosition {
    /* background-image: url('../assets/noticeImg/kuaidiPicture.png'); */
    background-size: contain;
    width: 1058px;
    height: 565px;
    position: absolute;
    top: 206px;
    left: 0px;
}

.total .order .announcementSubject .picturePosition .pictureUp_img_Notice_img {
    width: 1058px;
    height: 565px;
}

.total .order .announcementSubject .contentSubject01 {
    font-size: 16px;
    color: rgb(111, 111, 111);
    line-height: 1.2;
    text-align: left;

    position: absolute;
    top: 801px;
    text-indent: 32px;
}

.total .changhengxian {
    background-color: rgb(133, 133, 133);
    position: absolute;
    left: 372px;
    top: 1448px;
    width: 1201px;
    height: 1px;
    z-index: 44;
}

.total .downLogoPosition {
    position: relative;
    height: 0px;
    top: 252px;
}
</style>