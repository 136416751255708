<template>
    <div class="total">
        <HeadView />
        <div class="head_down001">
            <div class="aircraftImg"></div>
        </div>

        <div class="check_circle_size">
            <div class="kuaidi_position"></div>
        </div>

        <div class="check_background_size">
            <el-input class="bigInput" v-model="textareaMessage" @input="changeProductName($event)" :rows="5"
                type="textarea" placeholder="请输入运单号开始查询，多订快递单号请用中文逗号隔开" />
            <div class="exWenzi" v-if="'queryOne' != queryStatus">
                例：RA987654321CN，RA923654321CN
            </div>
            <router-link :to="{ path: '/toSinglePage', query: { id: '95167e06959b470eb8964acab5f52f97' } }">
                <div class="ReceivingAndMailingPackages">收寄包裹打包规则</div>
            </router-link>
            <el-button class="querySelected" type="primary" plain @click="querySelected(textareaMessage)">开始查询</el-button>
        </div>
        <!-- <div class="xuanzekuang_z-index"> -->
            <el-select v-model="expressCompany" class="m-2 select01Position" placeholder="快递公司" size="large"
                :teleported="false" clearable>
                <el-option v-for="item in expressCompanyList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
        <!-- </div> -->

        <div class="add_check_background_size" v-if="'queryAll' == queryStatus" :style="{ height: query_all_height }">
            <div class="multipleQueries">
                <div class="chaxunxian"></div>
                <div class="tableSize">
                    <div class="totic_name">运单号</div>
                    <div class="kuaidi_name">快递公司</div>
                    <div class="kuaidi_status">快递状态</div>
                    <div class="postion_jindu">地点和跟踪进度</div>
                    <div class="caozuo_name">操作</div>
                    <div class="top_position_list">
                        <div v-for="(item, index) of tableData">
                            <div class="queryAllListMessage">
                                <div class="totic_list_content">{{ item.orderInfoEntity.waybillnumber }}</div>
                                <div class="kuaidi_list_gongsi">{{ '韵达快递' }}</div>
                                <div class="kuaidi_list_status">{{ item.scannodetext.includes("派件") == true ? '派件中' : '运输中'
                                }}</div>
                                <div class="didian_list_jindu">
                                    <div class="didian_list_jindu_size">{{ item.scannodetext }}</div>
                                </div>
                                <a class="caozuo_list_position" href="javaScript:void(0);"
                                    @click="clickCheck(index, item.orderInfoEntity.id)">
                                    <div class="kuangzi" v-if="index == num" style="color: #0b60a6;border-color: #0b60a6;">
                                        <div class="wenzichakan" style="color: #0b60a6;border-color: #0b60a6;">查看</div>
                                    </div>
                                    <div class="kuangzi" v-if="index != num" style="color: #000000;border-color: #000000;">
                                        <div class="wenzichakan" style="color: #000000;border-color: #000000;">查看</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="query_one_kuaidi" v-if="'queryOne' == queryStatus" :style="{ height: query_one_height }">
            <a href="javaScript:void(0);" @click="clickReturn()">
                <div class="huituijiantou"></div>
            </a>
            <div class="yundanhao">{{ oneCourierMessage.waybillnumber }}</div>
            <div class="kuaidigongsi">{{ oneCourierMessage.expressCompany }}</div>
            <div class="kuaidizhuangtai">{{ oneCourierMessage.deliveryStatus }}</div>
            <div class="chaxunxian01"></div>
            <a href="javaScript:void(0);" @click="clickSortTime()">
                <div class="tiaojie_time">时间</div>
                <div class="tiaojie_time_img"></div>
            </a>
            <div class="position_genzong_jindu">地点和跟踪进度</div>
            <div class="queryOneList" v-if="sort == '0'">
                <div v-for="(item, index) of oneCourierMessage.oneCourierMessageOther">
                    <div class="pageInfo">
                        <div class="time_box">
                            <div class="year_month_day" v-if="index == 0" style="color: #0b60a6">{{
                                item.scantime.slice(0, 10) }}</div>
                            <div class="time_week" v-if="index == 0" style="color: #0b60a6">{{ item.scantime.slice(10, 16) + ' ' + item.scantime.slice(19,22) }}
                            </div>
                            <div class="year_month_day" v-if="index != 0">{{ item.scantime.slice(0, 10) }}</div>
                            <div class="time_week" v-if="index != 0">{{ item.scantime.slice(10, 16) + ' ' + item.scantime.slice(19,22) }}</div>
                        </div>
                        <div class="jindu_box">
                            <div class="jindu_node_up" v-if="index != 0"></div>
                            <div class="jindu_node">
                                <div class="duigou" v-if="index == 0"></div>
                                <div class="fanxiangduigou" v-if="index != 0"></div>
                            </div>
                            <div class="jindu_node_down"
                                v-if="(index + 1) != oneCourierMessage.oneCourierMessageOther.length"></div>
                        </div>
                        <div class="wenzi_box">
                            <div class="position_schedule" v-if="index == 0" style="color: #0b60a6">{{ item.scannodetext }}
                            </div>
                            <div class="position_schedule" v-if="index != 0">{{ item.scannodetext }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="queryOneList" v-if="sort == '1'">
                <div v-for="(item, index) of oneCourierMessage.oneCourierMessageOther">
                    <div class="pageInfo">
                        <div class="time_box">
                            <div class="year_month_day"
                                v-if="(index + 1) == oneCourierMessage.oneCourierMessageOther.length"
                                style="color: #0b60a6">{{ item.scantime.slice(0, 10) }}</div>
                            <div class="year_month_day"
                                v-if="(index + 1) != oneCourierMessage.oneCourierMessageOther.length">{{
                                    item.scantime.slice(0, 10) }}
                            </div>
                            <div class="time_week" v-if="(index + 1) == oneCourierMessage.oneCourierMessageOther.length"
                                style="color: #0b60a6">{{ item.scantime.slice(10, 16) + ' ' + item.scantime.slice(19,22) }}</div>
                            <div class="time_week" v-if="(index + 1) != oneCourierMessage.oneCourierMessageOther.length">{{
                                item.scantime.slice(10, 16) + ' ' + item.scantime.slice(19,22) }}</div>
                        </div>
                        <div class="jindu_box">
                            <div class="jindu_node_up" v-if="index != 0"></div>
                            <div class="jindu_node">
                                <div class="duigou" v-if="(index + 1) == oneCourierMessage.oneCourierMessageOther.length">
                                </div>
                                <div class="fanxiangduigou"
                                    v-if="(index + 1) != oneCourierMessage.oneCourierMessageOther.length"></div>
                            </div>
                            <div class="jindu_node_down"
                                v-if="(index + 1) != oneCourierMessage.oneCourierMessageOther.length"></div>
                        </div>
                        <div class="wenzi_box">
                            <div class="position_schedule"
                                v-if="(index + 1) == oneCourierMessage.oneCourierMessageOther.length"
                                style="color: #0b60a6">{{ item.scannodetext }}</div>
                            <div class="position_schedule"
                                v-if="(index + 1) != oneCourierMessage.oneCourierMessageOther.length">{{ item.scannodetext
                                }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bulletin">
            <div class="bulletin_img"></div>
        </div>
        <div class="notice">公告</div>
        <div class="horizontalBar"></div>

        <div class="totalPicture">
            <div class="picture" v-for="item of noticeList">
                <router-link :to="{ path: '/toNoticeDetails', query: { id: item.id } }">
                    <div class="pictureUp">
                        <div class="pictureUp_img">
                            <img :src="[[item.img]]" class="pictureUp_img_Notice_img">
                        </div>
                    </div>
                    <div class="pictureDown">
                        <div class="announcementTopic">
                            {{ item.total }}
                        </div>
                        <div class="announcementContent" v-html="[[item.description]]">
                        </div>
                        <div class="announcementTime">
                            {{ item.createDate }}
                        </div>
                        <div class="arrow"></div>
                    </div>
                </router-link>
            </div>
        </div>

        <router-link to="/toNotice">
            <div class="more">
                <div class="moreContent">MORE</div>
            </div>
        </router-link>
        <div class="news">
            <div class="news_img"></div>
        </div>
        <div class="hotNews">热点新闻</div>
        <div class="horizontalBar01"></div>

        <div class="totalPicture01">
            <div class="picture" v-for="item of newsList">
                <router-link :to="{ path: '/toNewsDetails', query: { id: item.id } }">
                    <div class="pictureUp">
                        <div class="pictureUp_img">
                            <img :src="[[item.img]]" class="pictureUp_img_Notice_img">
                        </div>
                    </div>
                    <div class="pictureDown">
                        <div class="announcementTopic">
                            {{ item.total }}
                        </div>
                        <div class="announcementContent" v-html="[[item.description]]">
                        </div>
                        <div class="announcementTime">
                            {{ item.createDate }}
                        </div>
                        <div class="arrow"></div>
                    </div>
                </router-link>
            </div>
        </div>

        <router-link to="/toNews">
            <div class="more01">
                <div class="moreContent">MORE</div>
            </div>
        </router-link>

        <div class="service">
            <div class="serviceCharacters_img">
            </div>
            <div class="serviceRegulationsCharacters">服务条例</div>
            <div class="serviceBox">
                <div class="round01">
                    <div class="kuaidi"></div>
                </div>
                <div class="standardExpressDelivery">标准快递</div>
                <div class="round02">
                    <div class="cangchu"></div>
                </div>
                <div class="warehousingServices">仓储服务</div>
                <div class="round03">
                    <div class="zengzhi"></div>
                </div>
                <div class="valueAddedServices">增值服务</div>
                <router-link :to="{ path: '/toSinglePage', query: { id: '859b6e54b5374d74bcfc469775b2c038' } }">
                    <div class="arrowBox">
                        <div class="arrow01"></div>
                    </div>
                </router-link>
            </div>
            <div class="mapPosition">
            </div>
        </div>
        <div class="tail_position_index">
            <TailView />
        </div>
    </div>
</template>

<script>
import HeadView from '@/components/comm/HeadView';
import TailView from '@/components/comm/TailView';
import axios from "@/utils/request";
import common from '@/utils/common';

export default {
    data() {
        return {
            noticeList: [],
            newsList: [],
            num: '-1',
            query_one_height: '',
            query_all_height: '',
            sort: '0',
            queryStatus: '',
            oneCourierMessage: {
                waybillnumber: '',
                expressCompany: '韵达快递',
                deliveryStatus: '',
                oneCourierMessageOther: []
            },
            tableData: [],
            expressCompanyList: [
                // {
                //     id: '1',
                //     name: '顺丰'
                // },
                {
                    id: '2',
                    name: '韵达快递'
                },
                // {
                //     id: '3',
                //     name: '中通'
                // },
                // {
                //     id: '4',
                //     name: '圆通'
                // }
            ],
            // deliveryStatusList: [
            //     {
            //         id: '1',
            //         name: '已下单'
            //     },
            //     {
            //         id: '2',
            //         name: '正在运输'
            //     },
            //     {
            //         id: '6',
            //         name: '正在派送'
            //     },
            //     {
            //         id: '4',
            //         name: '已送达'
            //     }
            // ],
            expressCompany: '',
            deliveryStatus: '',
            textareaMessage: ''
        }
    },
    created() {
        var that = this
        that.getHotNotice();
        that.getHotNews();
    },
    methods: {
        getHotNotice() {
            var that = this
            axios({
                methods: 'post',
                url: '/api/noticeAndNews/clickNoticeGetMessage?categoryId=ff35bfc6901247b0bb0447a4982be54b',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                var list = []
                for (var i = 0; i < 3; i++) {
                    var obj = {
                        id: response.data.list[i].id,
                        total: response.data.list[i].title,
                        description: response.data.list[i].description.slice(0, 40) + "...",
                        createDate: response.data.list[i].createDate,
                        img: common.GlobalUrl + response.data.list[i].image
                    }
                    list[i] = obj;
                }
                that.noticeList = list
            });
        },
        getHotNews() {
            var that = this
            axios({
                methods: 'post',
                url: '/api/noticeAndNews/clickNoticeGetMessage?categoryId=ddff81cde79f4ca9949f19a83a66ac1d',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                var list = []
                for (var i = 0; i < 3; i++) {
                    var obj = {
                        id: response.data.list[i].id,
                        total: response.data.list[i].title,
                        description: response.data.list[i].description.slice(0, 40) + "...",
                        createDate: response.data.list[i].createDate,
                        img: common.GlobalUrl + response.data.list[i].image
                    }
                    list[i] = obj;
                }
                that.newsList = list
            });
        },
        querySelected(textareaMessage) {
            var that = this
            if (textareaMessage == '') {
                common.msg("单号为空，请添加单号后再点击查询");
                return false;
            }
            if (textareaMessage.indexOf(',') != -1){
                common.msg("请使用中文逗号'，'作为多条运单号隔断");
                return false;
            }
            var s = common.load()
            axios({
                methods: 'post',
                url: '/api/noticeAndNews/querySelected?textareaMessage=' + textareaMessage,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                s.close()
                var session_id = sessionStorage.getItem('SESSION_ID')
                if(session_id == null){
                    localStorage.setItem('query_number', localStorage.getItem('query_number') == null ? common.encryption(1001) : common.encryption((parseInt(common.decrypt(localStorage.getItem('query_number')))) + parseInt(1)))
                }else{
                    localStorage.setItem('query_number', common.encryption('1001'))
                }
                if(parseInt(common.decrypt(localStorage.getItem('query_number'))) > 1004){
                    common.msg("未登录只能查询三次订单信息")
                    return false
                }
                if (response.code == "000001") {
                    that.query_all_height = (response.data.length * 55 + 100) + "px"
                    that.queryStatus = "queryAll"
                    that.tableData = response.data
                } else {
                    common.msg(response.message)
                    that.tableData = null
                    that.query_all_height = '62px'
                    return false;
                }
            }).catch(() => {
                common.msg("系统出现异常");
                s.close()
                that.tableData = null
                that.query_all_height = '62px'
                return false;
            });
        },
        changeProductName() {
            var that = this
            that.$forceUpdate()
        },
        clickCheck(index, id) {
            var that = this
            that.num = index
            that.chakan_status = 1
            var s = common.load();
            axios({
                methods: 'post',
                url: '/api/noticeAndNews/querySelectedById?id=' + id,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                s.close()
                if (response.code == "000001") {
                    for(var i = 0;i<response.data.length;i++){
                        var day = ''
                        if(new Date(response.data[i].scantime).getDay() == 0){
                            day = '星期日'
                        }
                        if(new Date(response.data[i].scantime).getDay() == 1){
                            day = '星期一'
                        }
                        if(new Date(response.data[i].scantime).getDay() == 2){
                            day = '星期二'
                        }
                        if(new Date(response.data[i].scantime).getDay() == 3){
                            day = '星期三'
                        }
                        if(new Date(response.data[i].scantime).getDay() == 4){
                            day = '星期四'
                        }
                        if(new Date(response.data[i].scantime).getDay() == 5){
                            day = '星期五'
                        }
                        if(new Date(response.data[i].scantime).getDay() == 6){
                            day = '星期六'
                        }
                        response.data[i].scantime = response.data[i].scantime + day
                    }
                    that.oneCourierMessage.oneCourierMessageOther = response.data
                    that.oneCourierMessage.waybillnumber = response.data[0].orderInfoEntity.waybillnumber
                    if (response.data[0].scannodetext.includes("派件")) {
                        that.oneCourierMessage.deliveryStatus = '派件中'
                    } else {
                        that.oneCourierMessage.deliveryStatus = '运输中'
                    }
                    that.query_one_height = (response.data.length * 70 + 110) + "px";
                    that.queryStatus = "queryOne"
                }
            }).catch(() => {
                s.close()
                common.msg('此单号出现问题，请联系管理员解决');
            });
        },
        clickReturn() {
            var that = this
            that.queryStatus = "queryAll"
        },
        clickSortTime() {
            var that = this
            that.oneCourierMessage.oneCourierMessageOther.reverse()
            if (that.sort == 0) {
                that.sort = 1
            } else {
                that.sort = 0
            }
        }
    },
    components: {
        HeadView,
        TailView
    }
}
</script>

<!-- 记得删除scoped 还有一个文件需要删除（可能已经删除了） -->
<style >
#app {
    width: 1920px;
}

#app .select01Position .el-select__popper {
    position: relative;
    left: 29px !important;
}

.total {
    width: 1920px;
    opacity: 1;
}

.total .head {
    width: auto;
    height: 91px;
    border-radius: 0px 0px 0px 0px;

}

.total .head .logoImg {
    position: absolute;
    width: 16rem;
    height: 4.4rem;
    left: 300px;
    top: 15px;
}

.total .head_down001 {
    top: 91px;
    width: auto;
    height: 765px;
    opacity: 1;
}

.total .head_down001 .aircraftImg {
    position: absolute;
    background-image: url('../assets/indexImg/aircraft.png');
    background-size: cover;
    width: 1920px;
    height: 765px;

}

.total .check_all_size {
    position: absolute;
    left: 371px;
    top: 688px;
    width: 1173px;
    height: 288px;
}


.total .check_circle_size {
    width: 116px;
    height: 116px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 5px 10px 0px rgba(108, 108, 108, 0.16);
    border-radius: 50% 50%;
    left: 900px;
    top: 688px;
    position: absolute;
}

.total .check_circle_size .kuaidi_position {
    background-image: url('../assets/indexImg/kuaidi.png');
    background-size: cover;
    width: 50px;
    height: 51px;
    z-index: 126;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.total .check_background_size {
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 5px 10px 0px rgba(108, 108, 108, 0.16);
    border-radius: 25px 25px 25px 25px;
    position: absolute;
    left: 372px;
    top: 746px;
    width: 1173px;
    height: 227px;
    /* -58 */
    z-index: 124;
}

.total .check_background_size .bigInput {
    position: absolute;
    top: 50px;
    left: 50px;
    z-index: 124;
    width: 823px;
    height: 120px;
}

.total .check_background_size .exWenzi {
    font-size: 14px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: absolute;
    left: 50px;
    top: 179px;
    z-index: 125;
    font-weight: 600;
}

.total .check_background_size .ReceivingAndMailingPackages {
    font-size: 14px;
    color: rgb(11, 96, 166);
    line-height: 1.2;
    position: absolute;
    left: 760px;
    top: 179px;
    z-index: 125;
}

/* .total .xuanzekuang_z-index {
    height: 120px;
    width: 245px;
    position: absolute;
    z-index: 400;
    top: 795px;
    left: 1313px;
} */

.total .select01Position {
    position: absolute;
    z-index: 400;
    top: 795px;
    left: 1237px;
}

/* .total .xuanzekuang_z-index .select02Position {
    position: absolute;
    left: -24px;
    top: 61px;
} */

.total .check_background_size .querySelected {
    width: 239px;
    height: 52px;
    background-color: #242a7d;
    color: white;
    font-size: 20px;

    position: absolute;
    left: 893px;
    top: 115px;
}

.el-tooltip__trigger {
    width: 235px;
}

.el-input--suffix {
    height: 52px;
}

.check_background_size {

    position: absolute;
    left: 372px;
    top: 746px;
    width: 1173px;
    height: 231px;
    /* -58 */
    z-index: 124;
}

.total .add_check_background_size {
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 5px 10px 0px rgba(108, 108, 108, 0.16);
    border-radius: 0px 0px 25px 25px;
    position: absolute;
    top: 948px;
    left: 372px;
    width: 1173px;
    height: 62px;
    z-index: 150;
}

.total .add_check_background_size .chaxunxian {
    background-color: rgb(194, 194, 194);
    position: absolute;
    left: 53px;
    top: 9px;
    width: 1093px;
    height: 1px;
}

.total .add_check_background_size .tableSize {
    width: 1100px;
    position: absolute;
    top: 5px;
    left: 10px;
    right: 0;
    z-index: 100;
    margin: auto;
}

.total .add_check_background_size .tableSize .totic_name {
    font-size: 16px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: absolute;
    top: 22px;
    left: 9px;
    font-weight: 600;
}

.total .add_check_background_size .tableSize .kuaidi_name {
    font-size: 16px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: absolute;
    top: 22px;
    left: 210px;
    font-weight: 600;
}

.total .add_check_background_size .tableSize .kuaidi_status {
    font-size: 16px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: absolute;
    top: 22px;
    left: 390px;
    font-weight: 600;
}

.total .add_check_background_size .tableSize .postion_jindu {
    font-size: 16px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: absolute;
    top: 22px;
    left: 517px;
    font-weight: 600;
}

.total .add_check_background_size .tableSize .caozuo_name {
    font-size: 16px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: absolute;
    top: 22px;
    left: 1003px;
    font-weight: 600;
}

.total .add_check_background_size .tableSize .top_position_list {
    width: 1100px;
    height: auto;
    position: absolute;
    top: 20px;
}

.total .add_check_background_size .tableSize .top_position_list .queryAllListMessage {
    width: 1080px;
    height: 55px;
    position: relative;
    top: 54px;
    left: 9px;
}

.total .add_check_background_size .tableSize .queryAllListMessage .totic_list_content {
    font-size: 14px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: absolute;
    top: 0px;
    left: 0px;
    font-weight: 600;
}

.total .add_check_background_size .tableSize .queryAllListMessage .kuaidi_list_gongsi {
    font-size: 14px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: absolute;
    top: 0px;
    left: 201px;
    font-weight: 600;
}

.total .add_check_background_size .tableSize .queryAllListMessage .kuaidi_list_status {
    font-size: 14px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: absolute;
    top: 0px;
    left: 381px;
    font-weight: 600;
}

.total .add_check_background_size .tableSize .queryAllListMessage .didian_list_jindu {
    width: 344px;
    height: 40px;
    position: absolute;
    top: -10px;
    left: 508px;
}

.total .add_check_background_size .tableSize .queryAllListMessage .didian_list_jindu .didian_list_jindu_size {
    font-size: 14px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: absolute;
    top: 0px;
    font-weight: 600;
    left: 0px;
    text-align: left;
}

.total .add_check_background_size .tableSize .queryAllListMessage .caozuo_list_position {
    position: absolute;
    left: 964px;
    top: -7px;
}

.total .add_check_background_size .tableSize .queryAllListMessage .caozuo_list_position .kuangzi {
    border-width: 3px;
    border-color: rgb(0, 0, 0);
    border-style: solid;
    width: 90px;
    height: 24px;
}

.total .add_check_background_size .tableSize .queryAllListMessage .caozuo_list_position .kuangzi .wenzichakan {
    font-weight: 600;
    position: absolute;
    font-size: 14px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    left: 34px;
    top: 6px;
}

.total .query_one_kuaidi {
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 5px 10px 0px rgba(108, 108, 108, 0.16);
    border-radius: 0px 0px 25px 25px;
    position: absolute;
    top: 952px;
    left: 372px;
    width: 1173px;
    height: 580px;
    z-index: 200;
}

.total .query_one_kuaidi .huituijiantou {
    background-image: url('../assets/indexImg/huituijiantou.png');
    background-size: cover;
    width: 57px;
    height: 36px;
    position: absolute;
    top: -30px;
    left: 50px;

}

.total .query_one_kuaidi .yundanhao {
    font-size: 24px;
    color: rgb(22, 22, 22);
    line-height: 1.2;

    position: absolute;
    top: -25px;
    left: 130px;
}

.total .query_one_kuaidi .kuaidigongsi {
    font-size: 24px;
    color: rgb(22, 22, 22);
    line-height: 1.2;

    position: absolute;
    top: -25px;
    left: 385px;
}

.total .query_one_kuaidi .kuaidizhuangtai {
    font-size: 24px;
    color: rgb(22, 22, 22);
    line-height: 1.2;

    position: absolute;
    top: -25px;
    left: 527px;
}

.total .query_one_kuaidi .chaxunxian01 {
    background-color: rgb(194, 194, 194);
    position: absolute;
    left: 53px;
    top: 20px;
    width: 1093px;
    height: 1px;
}

.total .query_one_kuaidi .tiaojie_time {
    font-size: 18px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: absolute;
    font-weight: 550;
    left: 53px;
    top: 42px;
}

.total .query_one_kuaidi .tiaojie_time_img {
    background-image: url('../assets/indexImg/tiaojie_time.png');
    background-size: cover;
    width: 16px;
    height: 19px;
    position: absolute;
    left: 96px;
    top: 43px;
}

.total .query_one_kuaidi .position_genzong_jindu {
    font-size: 18px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: absolute;
    font-weight: 600;
    left: 404px;
    top: 42px;
}

.total .query_one_kuaidi .queryOneList {
    width: 1078px;
    height: 379px;
    position: absolute;
    left: 50px;
    top: 88px;
}

.total .query_one_kuaidi .queryOneList .pageInfo {
    height: 70px;
    width: 1078px;
    position: relative;
    top: -5px;
}

.total .query_one_kuaidi .queryOneList .pageInfo .time_box {
    width: 130px;
    height: 35px;
    text-align: left;
    position: relative;
    left: 0px;
    top: 13px;
}

.total .query_one_kuaidi .queryOneList .pageInfo .time_box .year_month_day {
    font-size: 14px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: relative;
    top: 3px;
    font-weight: 500;
}

.total .query_one_kuaidi .queryOneList .pageInfo .time_box .time_week {
    font-size: 14px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: relative;
    font-weight: 500;
}

.total .query_one_kuaidi .queryOneList .pageInfo .jindu_box {
    width: 60px;
    height: 60px;
    position: relative;
    top: -16px;
    left: 193px;
}

.total .query_one_kuaidi .queryOneList .pageInfo .jindu_box .jindu_node_up {
    width: 1px;
    height: 25px;
    background-color: rgb(194, 194, 194);
    position: absolute;
    top: -21px;
    left: 26px;
}

.total .query_one_kuaidi .queryOneList .pageInfo .jindu_box .jindu_node {
    width: 22px;
    height: 22px;
    position: relative;
}

.total .query_one_kuaidi .queryOneList .pageInfo .jindu_box .jindu_node .duigou {
    background-image: url('../assets/indexImg/duigou.png');
    background-size: cover;
    width: 22px;
    height: 22px;
    position: absolute;
    left: 15.5px;
    top: 4px;
}

.total .query_one_kuaidi .queryOneList .pageInfo .jindu_box .jindu_node .fanxiangduigou {
    background-image: url('../assets/indexImg/fanxiangduigou.png');
    background-size: cover;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 2px;
    left: 17px;
    top: 6px;
}

.total .query_one_kuaidi .queryOneList .pageInfo .jindu_node_down {
    width: 1px;
    height: 25px;
    background-color: rgb(194, 194, 194);
    position: absolute;
    left: 26px;
    top: 26px;
}

.total .query_one_kuaidi .queryOneList .pageInfo .wenzi_box {
    height: 50px;
    width: 710px;
    position: relative;
    left: 362px;
    top: -77px;
    font-weight: 500;
}

.total .query_one_kuaidi .queryOneList .pageInfo .wenzi_box .position_schedule {
    font-size: 14px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: relative;
    top: 5px;
    text-align: left;
}

.total .bulletin {
    position: absolute;
    height: 47px;
    left: 769.217px;
    top: 1089.3px;
}

.bulletin_img {
    background-image: url('../assets/indexImg/bulletin.png');
    background-size: cover;
    width: 370px;
    height: 47px;
}

.total .notice {
    width: 100px;
    height: 25px;
    font-size: 26px;
    color: rgb(27, 38, 73);
    font-weight: normal;
    line-height: 0px;

    position: absolute;
    top: 1193.668px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.total .horizontalBar {

    width: 370px;
    height: 4px;
    background: #0B60A6;
    opacity: 1;

    position: absolute;
    left: 775px;
    top: 1214px;
    left: 4px;
    right: 0;
    margin: auto;
}


.total .totalPicture {
    width: auto;
    height: 495px;

    position: relative;
    top: 430px;
    padding: 0 340.5px;
    /** top 原72  311 */
}

.total .totalPicture .picture {
    width: 352px;
    height: 350px;

    border-radius: 6px 6px 6px 6px;
    opacity: 1;

    /* position: relative; */
    float: left;
    /* left: 371px; */
    padding: 0 30.5px;

}

.total .pictureUp {
    width: 352px;
    height: 350px;
    border-radius: 6px 6px 0px 0px;
    opacity: 1;
    position: relative;
}

.total .pictureUp .pictureUp_img {
    /* background-image: url('../assets/indexImg/announcementImage.png'); */
    background-size: cover;
    width: 352px;
    height: 350px;
}

.total .pictureUp .pictureUp_img .pictureUp_img_Notice_img {
    width: 352px;
    height: 350px;
}

.total .pictureDown {
    box-shadow: 0px 5px 10px 0px rgba(108, 108, 108, 0.16);
    position: relative;
    width: 352px;
    height: 143px;
    border-radius: 0px 0px 6px 6px;
    position: absolute;
    top: 350px;
}

.total .announcementTopic {
    font-size: 16px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: absolute;

    top: 30px;
    left: 15px;
    z-index: 46;
    font-weight: 600;
}

.total .announcementContent {
    font-size: 14px;
    color: rgb(111, 111, 111);
    line-height: 1.2;
    position: absolute;
    z-index: 47;
    width: 330px;

    text-align: left;
    top: 61px;
    left: 15px;
    z-index: 46;
}

.total .announcementTime {
    font-size: 14px;
    color: rgb(195, 195, 195);
    line-height: 1.2;
    position: absolute;
    top: 114px;
    bottom: 12px;
    left: 15px;
    z-index: 46;
}

.total .arrow {
    background-image: url('../assets/indexImg/arrow.png');
    background-size: cover;
    width: 33px;
    height: 14px;
    position: absolute;
    z-index: 48;

    bottom: 15px;
    z-index: 46;
    left: 290px;
}

.total .more {
    width: 198px;
    height: 58px;
    opacity: 1;
    border: 2px solid #0B60A6;

    position: relative;
    top: 481px;
    left: 859px;
    font-size: 26px;
    color: rgb(11, 96, 166);
    line-height: 1.2;
}

.total .moreContent {
    position: absolute;
    height: 29px;
    left: 60px;
    top: 14px;
}

.total .news {
    position: relative;
    top: 582px;
    width: auto;
    height: 47px;
}

.news_img {
    background-image: url('../assets/indexImg/news.png');
    background-size: cover;
    position: absolute;
    width: 224px;
    height: 52px;
    left: 854.5px;
}

.total .hotNews {
    height: 25px;
    font-size: 26px;
    font-weight: normal;
    color: #1B2649;
    line-height: 0px;

    position: absolute;
    top: 2094px;
    left: 910px;
}

.total .horizontalBar01 {

    width: 370px;
    height: 4px;
    background: #0B60A6;
    opacity: 1;

    position: absolute;
    top: 2130px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.total .totalPicture01 {
    width: auto;
    height: 510px;

    position: relative;
    top: 727px;
    padding: 0 340.5px;
    /** top 原72  311 */
}

.total .totalPicture01 .picture {
    width: 352px;
    height: 350px;

    border-radius: 6px 6px 6px 6px;
    opacity: 1;

    /* position: relative; */
    float: left;
    /* left: 371px; */
    padding: 0 30.5px;
}

.total .more01 {
    width: 198px;
    height: 58px;
    opacity: 1;
    border: 2px solid #0B60A6;

    position: relative;
    top: 776px;
    left: 859px;
    font-size: 26px;
    color: rgb(11, 96, 166);
    line-height: 1.2;
}

.total .service {
    position: relative;
    top: 869px;
    background-color: rgb(245, 245, 245);
    left: 0px;
    width: 1920px;
    height: 577px;
    z-index: 89;
}

.total .service .serviceCharacters_img {
    background-image: url('../assets/indexImg/serviceRegulation.png');
    background-size: cover;
    width: 491px;
    height: 138px;
    position: absolute;
    left: 356px;
    top: 52px;
}

.total .service .serviceRegulationsCharacters {
    text-align: left;
    position: relative;
    width: 300px;
    top: 212px;
    left: 357px;
    font-size: 26px;
    color: rgb(27, 38, 73);
    line-height: 1.2;
}

.total .service .serviceBox {
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    position: absolute;
    width: 489px;
    height: 258px;
    z-index: 93;
    top: 272px;
    left: 350px;
}

.total .service .round01 {
    border-radius: 50%;
    background-color: rgb(27, 38, 73);
    position: relative;
    width: 70px;
    height: 70px;
    z-index: 94;
    top: 45px;
    left: 75px;
    float: left;
}

.total .service .round01 .kuaidi {
    background-image: url('../assets/sever/kuaidi.png');
    background-size: cover;
    width: 42px;
    height: 35px;
    position: absolute;
    top: 17px;
    left: 15px;
}

.total .service .round02 {
    border-radius: 50%;
    background-color: rgb(27, 38, 73);
    position: relative;
    width: 70px;
    height: 70px;
    z-index: 94;
    top: 45px;
    left: 145px;
    float: left;
}

.total .service .round02 .cangchu {
    background-image: url('../assets/sever/cangchu.png');
    background-size: cover;
    width: 37px;
    height: 33px;
    position: absolute;
    top: 17px;
    left: 17px;
}

.total .service .round03 {
    border-radius: 50%;
    background-color: rgb(27, 38, 73);
    position: relative;
    width: 70px;
    height: 70px;
    z-index: 94;
    top: 45px;
    left: 215px;
    float: left;
}

.total .service .round03 .zengzhi {
    background-image: url('../assets/sever/zengzhi.png');
    background-size: cover;
    width: 30px;
    height: 37px;
    position: absolute;
    top: 16px;
    left: 20px;
}

.total .service .standardExpressDelivery {
    font-size: 16px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: absolute;
    left: 75px;
    top: 150px;
    z-index: 97;
}

.total .service .warehousingServices {
    font-size: 16px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: absolute;
    top: 150px;
    left: 217px;
    z-index: 97;
}

.total .service .valueAddedServices {
    font-size: 16px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: absolute;
    top: 150px;
    left: 360px;
    z-index: 97;
}

.total .service .arrowBox {
    border-width: 2px;
    border-color: rgb(11, 96, 166);
    border-style: solid;
    background-color: rgba(255, 255, 255, 0);
    position: absolute;
    width: 124px;
    height: 37px;
    z-index: 101;
    top: 185px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.total .service .arrow01 {
    background-image: url('../assets/indexImg/arrow.png');
    background-size: cover;
    position: relative;
    width: 33px;
    height: 14px;
    left: 46px;
    top: 11px;
}

.total .service .mapPosition {
    background-image: url('../assets/indexImg/map.png');
    background-size: cover;
    opacity: 0.741;
    position: absolute;
    width: 874px;
    height: 523px;
    top: 27px;
    left: 923px;
}

.total .bottomTop {
    width: auto;
    height: 20px;
    background: #242A7D;
    opacity: 1;

    position: relative;
    top: 889px;
}

.total .bottom {
    width: auto;
    height: 223px;
    background: #1B2649;
    opacity: 1;

    position: relative;
    top: 889px;
}

.total .bottom .bottomLogoPosition {
    position: absolute;
    left: 350px;
    width: 166px;
    height: 118px;
    top: 30px;
}

.total .bottom .jumpBox {
    position: relative;
    width: 160px;
    height: 50px;
    top: 98px;
    left: 600px;
}

.total .bottom .font01 {
    font-size: 14px;
    color: rgb(255, 255, 255);
    line-height: 1.2;
    position: relative;
    text-decoration: none;
}

.total .bottom .font01 {
    font-size: 14px;
    color: rgb(255, 255, 255);
    line-height: 1.2;
    position: absolute;
    left: 0px;
    text-decoration: none;
}

.total .bottom .font02 {
    font-size: 14px;
    color: rgb(255, 255, 255);
    line-height: 1.2;
    position: absolute;
    right: 0px;
    text-decoration: none;
}

.total .bottom .font03 {
    font-size: 14px;
    color: rgb(255, 255, 255);
    line-height: 1.2;
    position: absolute;
    bottom: 0px;
    left: 0px;
    text-decoration: none;
}

.total .bottom .font04 {
    font-size: 14px;
    color: rgb(255, 255, 255);
    line-height: 1.2;
    position: absolute;
    bottom: 0px;
    right: 0px;
    text-decoration: none;
}

.total .bottom .verticalStick {
    background-color: rgb(255, 255, 255);
    position: absolute;
    width: 1px;
    height: 92px;
    z-index: 118;
    left: 1067px;
    top: 59px;
}

.total .bottom .contactUs {
    position: absolute;
    top: 59px;
    width: 290px;
    height: 90px;
    left: 1124px;
}

.total .bottom .font05 {
    color: rgb(255, 255, 255);
    position: absolute;
    top: -3px;
    font-size: 18px;
    line-height: 1.333;
}

.total .bottom .font06 {
    color: rgb(255, 255, 255);
    position: absolute;
    top: 27px;
    font-size: 14px;
    line-height: 1.333;
    text-align: left;
}

.total .bottom .font07 {
    color: rgb(255, 255, 255);
    position: absolute;
    top: 50px;
    font-size: 14px;
    line-height: 1.333;
    text-align: left;
}

.total .bottom .font08 {
    color: rgb(255, 255, 255);
    position: absolute;
    top: 73px;
    font-size: 14px;
    line-height: 1.333;
    text-align: left;
}

.total .bottom .ROCode {
    width: 91px;
    height: 91px;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    background-color: #FFFFFF;
    position: absolute;
    top: 60px;
    left: 1469px;
}

.total .bottom .RQCodeImg {
    position: relative;
    top: 5px;
}

.total .tail_position_index {
    position: relative;
    height: 10px;
    top: -20px;
}
</style>

