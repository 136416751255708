<template>
  <router-view />
</template>

<script>
/** 
 * 页面大小改变后，背景图片右边出现白边
 * 解决方式，页面大小发生改变后，刷新页面。
 */
export default {
  data() {
    return {
      screenWidth: null,
    }
  },
  created() {
    // window.onresize = () => {
    //   return (() => {
    //     this.screenWidth = document.body.clientWidth
    //   })()
    // }
  },
  watch: {
    screenWidth: {
      handler: function (val) {
        // console.log('页面宽度'+val)
        location.reload();
      }
    },
  }
}
</script>

<style>
/* <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"> */

html,
body {
  padding: 0;
  margin: 0;
  border: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
