<template>
    <HeadView />
    <div class="total">
        <div class="head_down01">
            <div class="notiveImg_sjdbtl" v-if="'sjdbtl' == bgStatus"></div>
            <div class="notiveImg_fw" v-if="'fw' == bgStatus"></div>
        </div>
        <div class="order">
            <div class="announcementSubject">
                <div class="announcementTitle">
                    {{ title }}
                </div>
                <div class="contentSubject" v-html="[[contentup]]"></div>
            </div>
        </div>
        <div class="changhengxian"></div>
        <div class="downLogoPosition">
            <TailView />
        </div>
    </div>
</template>

<script>
import HeadView from '@/components/comm/HeadView';
import TailView from '@/components/comm/TailView';
import axios from "@/utils/request";
import common from '@/utils/common';

export default {
    data() {
        return {
            id: this.$route.query.id,
            title: '',
            contentup: '',
            bgStatus: ''
        }
    },
    created() {
        var that = this;
        that.judeBackground(that.id)
        that.getRecordAndSend(that.id);
    },
    methods: {
        judeBackground(id) {
            var that = this
            if (id == '859b6e54b5374d74bcfc469775b2c038') {
                that.bgStatus = 'fw'
            } else if (id == '95167e06959b470eb8964acab5f52f97') {
                that.bgStatus = 'sjdbtl'
            }
        },
        getRecordAndSend(id) {
            var that = this
            axios({
                methods: 'post',
                url: '/api/noticeAndNews/getRecordAndSend?id=' + id,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((response) => {
                that.title = response.data.pagename;
                that.contentup = response.data.pagedate;
                if (response.code == "999999") {
                    common.msg(response.message)
                    return false
                }
            }).catch(() => {
                common.msg('出现问题')
            });
        },
    },
    components: {
        HeadView,
        TailView
    }
}
</script>

<style scoped>
.total {
    width: 1920px;
    height: 1263px;
}

.total .head_down01 {
    top: 91px;
    width: auto;
    height: 288px;
    opacity: 1;
}

.total .head_down01 .notiveImg_sjdbtl {
    background-image: url('../assets/noticeImg/shoujifuwuguize.png');
    background-size: contain;
    height: 288px;
    width: 1920px;
}

.total .head_down01 .notiveImg_fw {
    background-image: url('../assets/noticeImg/serviceBackground.png');
    background-size: contain;
    height: 288px;
    width: 1920px;
}

.total .order {
    width: auto;
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.total .order .announcementSubject {
    width: 1060px;
    height: 800x;
    position: absolute;
    top: 139px;
    left: 441px;
    z-index: 600;
}

.total .order .announcementSubject .announcementTitle {
    font-size: 24px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    font-weight: 600;

    position: absolute;
    left: 0;
    right: 0;
    top: -50px;
    margin: auto;

}

.total .order .announcementSubject .contentSubject {
    font-size: 16px;
    color: rgb(111, 111, 111);
    line-height: 1.2;
    text-align: left;

    position: absolute;
    top: 30px;
    text-indent: 32px;
}

.total .order .announcementSubject .picturePosition {
    /* background-image: url('../assets/noticeImg/kuaidiPicture.png'); */
    background-size: contain;
    width: 1058px;
    height: 565px;
    position: absolute;
    top: 206px;
    left: 0px;
}

.total .order .announcementSubject .picturePosition .pictureUp_img_Notice_img {
    width: 1058px;
    height: 565px;
}

.total .order .announcementSubject .contentSubject01 {
    font-size: 16px;
    color: rgb(111, 111, 111);
    line-height: 1.2;
    text-align: left;

    position: absolute;
    top: 801px;
    text-indent: 32px;
}

.total .changhengxian {
    background-color: rgb(133, 133, 133);
    position: absolute;
    left: 372px;
    top: 1438px;
    width: 1201px;
    height: 1px;
    z-index: 44;
}

.total .downLogoPosition {
    position: absolute;
    height: 0px;
    top: 603px;
    width: 1920px;
}
</style>