<template>
    <div class="total_login">
        <div class="head">
            <router-link to="/">
                <div class="head_img"></div>
                <div class="shugang">
                    <div class="tongxingzheng">通行证</div>
                </div>
            </router-link>
        </div>
        <div class="background">
            <div class="background_img"></div>
            <div class="background_kuang">
                <div class="username_password_login">账户名密码登录</div>
                <el-form ref="loginForm" label-width="90px">
                    <el-input v-bind:class="{username: old_pwd_class, isfail: usernamefail}" @blur="handleChange(1)" @focus="handleFouse(1)" autocomplete="new-password" v-model="uName" placeholder="手机号" />
                    <el-input v-bind:class="{password: old_pwd_class, isfail: paseworldfail}" @blur="handleChange(2)" @focus="handleFouse(2)" autocomplete="new-password" type="password" v-model="pwd" placeholder="密码" />
                    <div v-if="'1' == loginStatus">
                        <el-input v-bind:class="{code: old_pwd_class, isfail: codefail}" @blur="handleChange(3)" @focus="handleFouse(3)" autocomplete="new-password" v-model="code" placeholder="验证码" />
                        <div class="code_img_replace"><img :src="img" /></div>
                        <a class="replaceCodeStyle" href="javaScript:void(0);" @click="replaceCode()">换一换</a>
                    </div>
                    <el-button class="loginSubmit" type="primary" plain @click="toLogin()">登录</el-button>
                    <el-checkbox class="nextLogin" v-model="checked1" label="下次自动登录" size="large"
                        @click="autoLoginNextTime(checked1)" />
                </el-form>
                <div class="registerPosition">
                    <router-link to="/tochangePassword" class="forgotPassword_for">忘记密码</router-link>&nbsp;&nbsp; |&nbsp;&nbsp;
                    <router-link to="/toRegister" class="register">注册</router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "@/utils/request";
import common from '@/utils/common';
var qs = require('qs');

export default {
    data() {
        return {
            usernamefail: false,
            paseworldfail: false,
            codefail: false,
            old_pwd_class: true,
            uName: '',
            pwd: '',
            checked1: false,
            code: '',
            img: '',
            loginStatus: '0',
            logNumber: 0
        }
    },
    created() {
        if(null != localStorage.getItem('login_name')){
            var that = this
            that.uName = localStorage.getItem('login_name')
            that.pwd = common.decrypt(localStorage.getItem('login_pwd'))
        }
    },
    methods: {
        handleChange(e){
            var that = this
            if(e == 1 && that.uName == ''){
                that.usernamefail = true
            }
            if(e == 2 && that.pwd == ''){
                that.paseworldfail = true
            }
            if(e == 3 && that.code == ''){
                that.codefail = true
            }
        },
        handleFouse(e){
            var that = this
            if(e == 1 && that.uName == ''){
                that.usernamefail = false
            }
            if(e == 2 && that.pwd == ''){
                that.paseworldfail = false
            }
            if(e == 3 && that.code == ''){
                that.codefail = false
            }
        },
        autoLoginNextTime(e) {
        },
        replaceCode() {
            this.validateCodeServlet();
        },
        validateCodeServlet() {
            const T = new Date().getTime();
            this.img = '/suyou/servlet/validateCodeServlet?' + T;
        },
        toLogin() {
            var that = this
            var config = {
                method: 'post',
                url: '/a/logout',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                withCredentials: false
            };
            axios(config).then(function () {
                if (sessionStorage.getItem('SESSION_ID') != undefined) {
                    sessionStorage.removeItem('SESSION_ID')
                    sessionStorage.removeItem('USER_NAME')
                    sessionStorage.removeItem('user_id')
                    that.toLogin();
                } else {
                    that.login();
                }
            }).catch(function (error) {
                console.log(error);
            });
        },
        login() {
            var that = this;
            var data = {};
            var data = qs.stringify({
                'mobileLogin': true,
                'username': that.uName,
                'password': that.pwd,
                'validateCode': that.code
            });
            var config = {
                method: 'post',
                url: '/a/login?__ajax=true&validateCode='+that.code,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                data: data,
                withCredentials: false
            };
            axios(config).then(function (response) {
                var sessionid = response.sessionid;
                if (response.message != undefined) {
                    that.logNumber += 1;
                    if (-1 != response.message.indexOf("验证码错误") || that.logNumber >= 3) {
                        if (that.logNumber < 5) {
                            that.$confirm(response.message, '消息', {
                                confirmButtonText: 'OK',
                                showCancelButton: false,
                                showClose: false,
                                closeOnClickModal: false,
                                closeOnHashChange: false,
                                closeOnPressEscape: false
                            }).then(() => {
                                that.loginStatus = '1'
                                that.replaceCode()
                            })
                        }
                    } else {
                        common.msg(response.message)
                    }
                }
                if (sessionid != undefined) {
                    var nickname = response.name;
                    sessionStorage.setItem('SESSION_ID', common.encryption(sessionid));
                    sessionStorage.setItem('USER_NAME', nickname);
                    sessionStorage.setItem('user_id', common.encryption(response.id));
                    that.logNumber = 0;
                    localStorage.setItem('query_number', common.encryption(1001))
                    that.$confirm('登录成功', '消息', {
                        confirmButtonText: 'OK',
                        showCancelButton: false,
                        showClose: false,
                        closeOnClickModal: false,
                        closeOnHashChange: false,
                        closeOnPressEscape: false
                    }).then(() => {
                        if (that.checked1) {
                            localStorage.setItem('login_name', that.uName)
                            localStorage.setItem('login_pwd',common.encryption(that.pwd))
                        }
                        common.rout('/')
                    })
                }
            }).catch(function (error) {
                console.log(error);
            });
        }
    }
}
</script>
<style>

.isfail .el-input__wrapper {
    box-shadow: 0 0 0 1px red inset !important;
}

#app .el-checkbox__input.is-checked+.el-checkbox__label {
    color: black;
}

.total_login {
    width: 1920px;
    height: 1080px;
}

.total_login .head {
    width: 1920px;
    height: 91px;
}

.total_login .head .head_img {
    background-image: url('../assets/loginImg/head.png');
    background-size: cover;
    width: 288px;
    height: 71px;
    position: absolute;
    top: 9px;
    left: 360px;
}

.total_login .head .shugang {
    background-color: rgb(68, 68, 68);
    position: absolute;
    left: 663px;
    top: 22px;
    width: 2px;
    height: 53px;
    z-index: 25;
}

.tongxingzheng {
    font-size: 36px;
    width: 150px;
    color: rgb(68, 68, 68);
    line-height: 1.2;
    position: absolute;
    left: 0px;
    top: 4px;
    z-index: 24;
}


.total_login .background .background_img {
    background-image: url('../assets/loginImg/background.png');
    background-size: cover;
    position: absolute;
    width: 1920px;
    height: 989px;
}

.total_login .background .background_kuang {
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 5px 10px 0px rgba(108, 108, 108, 0.16);
    position: absolute;
    left: 661px;
    top: 319px;
    width: 586px;
    height: 461px;
    z-index: 10;
}

.total_login .background .background_kuang .username_password_login {
    font-size: 38px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: absolute;
    top: 46px;
    left: 0;
    right: 0;
    margin: auto;
    font-weight: 600;
    z-index: 17;
}

.total_login .background .username {
    z-index: 200;
    position: absolute;
    left: 13px;
    top: 122px;
    width: 499px;
    height: 54px;
}

.total_login .background .password {
    z-index: 200;
    position: relative;
    left: -30px;
    top: 195px;
    width: 499px;
    height: 54px;
}

.total_login .background .code {
    z-index: 200;
    position: relative;
    left: -31px;
    top: 213px;
    width: 499px;
    height: 54px;
}

.total_login .background .code_img_replace {
    width: 70px;
    height: 26px;
    position: absolute;
    top: 282px;
    left: 406px;
    z-index: 666;
}

.total_login .background .replaceCodeStyle {
    font-size: 14px;
    position: absolute;
    top: 285px;
    color: rgb(44, 117, 178);
    left: 486px;
    text-decoration: none;
    z-index: 666;
}

.total_login .background .loginSubmit {
    background-color: rgb(36, 42, 125);
    color: white;
    width: 499px;
    height: 54px;
    z-index: 40;
    position: relative;
    /* top: 269px; */
    top: 232px;
    left: 6px;
}

.total_login .background .nextLogin {
    font-size: 14px;
    color: rgb(22, 22, 22);
    line-height: 1.2;
    position: relative;
    top: 288px;
    right: 492px;
    width: 14px;
    height: 14px;
}

.total_login .background .registerPosition {
    width: 160px;
    height: 12px;
    font-size: 14px;
    font-weight: normal;
    color: #545454;
    line-height: 0px;

    position: relative;

    top: 258px;
    left: 407px;
}

.total_login .background .registerPosition .forgotPassword_for {
    text-decoration: none;
    color: black;
}

.total_login .background .registerPosition .register {
    text-decoration: none;
    color: black;
}
</style>