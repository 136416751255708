import { ElMessageBox } from "element-plus";
import router from "@/router";
import { Base64 } from 'js-base64';
import { ElLoading } from 'element-plus';

const GlobalUrl = "/static";
let SESSION_ID = '';
let USER_NAME = '';
var suffix = '_368fex2f48sefze';

const load = () => {
    return ElLoading.service()
}

const getFourStringLength = (number) => {
    var num = '';
    var random = new Array( 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',0, 1, 2, 3, 4, 5, 6, 7, 8, 9,);
    for (var i = 0; i < number; i++) {
        var index = Math.floor(Math.random() * 36);
        num += random[index];
    }
    return num;
}

/**
 * 加密
 */
const encryption = (password) =>{
    var newPwd = Base64.encode(password + suffix);
    return getFourStringLength(6) + newPwd;
}

/**
 * 解密
 */
const decrypt = (password) => {
    var newPwd = Base64.decode(password.slice(6));
    return newPwd.split('_')[0];
}

/**
 * 传递值弹出框
 * @param {message} message 消息内容
 */
const msg = (message) => {
    ElMessageBox.alert(message, '消息', {
        confirmButtonText: 'OK',
        showCancelButton: false,
        showClose: false,
        closeOnClickModal: false,
        closeOnHashChange: false,
        closeOnPressEscape: false
    })
}

/**
 * 跳转页面
 * @param {url} url 需要跳转的路径
 */
const rout = (url) => {
    router.push({
        path: url
    })
}

/**
 * 验证国内手机号码，返回Boolean值
 * @param {phone} phone 国内手机号码
 * @returns boolean 值
 */
const judePhone = (phone) =>{
    let reg = '0?(13|14|15|17|18|19)[0-9]{9}';
    console.log(phone.match(reg))
    if(phone.match(reg)){
        return true;
    }else{
        return false;
    }
}

export default {
    GlobalUrl,
    SESSION_ID,
    USER_NAME,
    judePhone,
    msg,
    rout,
    encryption,
    decrypt,
    load
}